.sort-expansion-wrap .topnav-list {
  display: flex;
  // background: #ffffff;
  // background-color: red;
  border-radius: 12px;
  // overflow: hidden;
  // width: 936px;
  margin: 0 auto;
  height: 384px;
  // margin-left: 360px;
  // margin-top: 40px;
  // box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  // box-shadow: 0 4px 28px #3c70d11f;
}
.sort-expansion-wrap .topnav-list .course-classify {
  position: relative;
  width: 296px;
  line-height: 40px;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border-radius: 12px 0px 0px 12px;
  z-index: 2;
  // overflow: hidden;
}
.sort-expansion-wrap .topnav-list .course-classify .classify-list {
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 9px;
  // background: rgba(255, 255, 255, 0.955);
  background-color: #ffffff;
  /* box-shadow: 0 0 10px 3px rgba(175, 176, 181, 0.2); */
  // border-radius: 2px 0 0 0 2px 2px 2px;
  border-radius: 12px 0px 0px 12px;
  z-index: 6;
  width: 298px;
  height: 384px;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item {
  padding: 12px 26px 12px 12px;
  width: 280px;
  height: 70px;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .classify-top {
  height: 22px;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .classify-top
  .classify-title {
  color: #344049;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .classify-top
  .iconfont {
  font-size: 10px;
  line-height: 21px;
  color: #999;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .hot-exams {
  padding-top: 6px;
  font-size: 12px;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .hot-exams
  .hot-exam {
  float: left;
  line-height: 1;
  margin-right: 15px;
  color: #999;
  font-size: 14px;
  cursor: pointer;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .hot-exams
  .hot-exam:hover {
  color: #4085ed;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .exam-list {
  display: none;
  /* display: flex; */
  position: absolute;
  left: 290px;
  top: 0;
  width: 656px;
  background: #f2f5ff;
  // box-shadow: 0 0 10px 3px rgba(175, 176, 181, 0.2);
  padding: 0px 30px;
  height: 384px;
  overflow: auto;
  border-radius: 0px 12px 12px 0px;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .exam-list
  .recommend-course {
  cursor: pointer;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #4085ed;
  padding: 0 10px;
  background: rgba(64, 133, 237, 0.05);
  border: 1px solid #4085ed;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .exam-list
  .exam-items {
  /* margin-left: 28px; */
  margin-top: 27px;
  display: flex;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .exam-list
  .exam-items
  .exam-item {
  float: left;
  position: relative;
  color: #666;
  font-size: 12px;
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 8px;
  // margin-right: 88px;
  cursor: pointer;
  // width: 140px;
  // background-color: skyblue;
  display: block;
  width: 120px;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .exam-list
  .exam-items
  .exam-item
  span {
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .exam-list
  .exam-items
  .exam-item
  .iconHOT {
  float: left;
  color: #ff6600;
  font-size: 10px;
  margin-left: 6px;
  margin-top: -12px;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .exam-list
  .exam-items
  .exam-item:hover {
  color: #4085ed;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item
  .exam-list
  .exam-items
  .exam-item-active {
  color: #4085ed;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item:hover {
  margin-left: -5px;
  background: #f2f5ff;
  border-left: 2px solid #4085ed;
}
.sort-expansion-wrap
  .topnav-list
  .course-classify
  .classify-list
  .classify-item:hover
  .exam-list {
  display: block;
}
.sort-expansion-wrap .topnav-list .course-classify:hover .classify-list {
  display: block;
}
.exam-title {
  color: #333333;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22.4px;
  white-space: nowrap;
  cursor: pointer;
  min-width: 150px;
}
.exam-title2 {
  display: flex;
  width: 528px;
  flex-wrap: wrap;
  margin-left: 28px;
  // background-color: pink;
}
.sort-expansion-wrap .topnav-list .nav-item {
  display: inline-block;
  // margin-right: 56px;
  line-height: 40px;
  font-size: 14px;
  // color: #fff;
}
.top-center-nav {
  display: flex;
  box-shadow: 0 4px 28px #3c70d11f;
  border-radius: 12px;
}
ul::-webkit-scrollbar {
  display: none;
}
