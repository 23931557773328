.recordContent {
  display: flex;
  width: 920px;
  height: 82px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background-color: #f7f8fa;
  margin-top: 24px;
}
.course-name {
  color: #333333;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.exchange-time {
  color: #9fa1a4;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.leftinfo {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.goStudy {
  display: flex;
  width: 80px;
  height: 32px;
  color: #e51600;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  border: 1px solid #fe4e4799;
  background: #fde8e6;
}
