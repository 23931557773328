.video-top-box{
    height: 68px;
    width: 100%;
    background: #222222;
    padding:0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .video-top-left{
        display: flex;
        height: 68px;
        align-items: center;
        .header-logo{
            width: auto;
            height: 50px;
            margin-top: 0;
            margin-right: 40px;
        }

        .video-top-crumb{
            color:#9FA1A4;
            display: flex;
            height: 36px;
            padding: 8px 20px;
            align-items: center;
            border-radius: 4px;
            background: #c9cdd41a;
            .bread-one {
                display: flex;
              }
              .bread-one-text {
                cursor: pointer;
              }
              .bread-one-icon {
                margin-left: 12px;
                margin-right: 12px;
              }
        }
    }
    .video-top-right{
            .header-login {
                display: flex;
                height: 22px;
                line-height: 22px;
                color: #C9CDD4;
            }
            .login-item {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .login-icon {
                width: 22px;
                height: 22px;
                background-color: blue;
            }
            .login-btn {
                margin: 0 6px;
            }
            .register-btn {
                margin-left: 6px;
                cursor: pointer;
            }
            .header-adylogin {
                display: flex;
                align-items: center;
                margin-left: 90px;
                width: 141px;
                height: 36px;
                color: #C9CDD4;
            }
            .header-adylogin .header-user {
                display: flex;
                align-items: center;
            }
            .header-adylogin .header-user .user-avatar {
                width: 36px;
                height: 36px;
            }
            .header-adylogin .header-user .user-name {
                color: #C9CDD4;
                font-family: "PingFang SC";
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                margin: 0 4px;
            }
            .header-adylogin .down-icon {
                width: 12px;
                height: 12px;
            }
    }   
}