.login-already {
  // position: absolute;
  // top: 40px;
  // right: 360px;
  // z-index: 2;
  width: 240px;
  height: 384px;
  border-radius: 10px;
  overflow: hidden;
  background: #d4e8ff;
  // box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 28px #3c70d11f;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.title-already {
  display: flex;
  align-items: center;
  /* margin-bottom: 24px; */
  padding: 46px 16px 14px 16px;
  gap: 12px;
}
.user-img {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  background: #fff;
  margin-left: 0;
  // border-radius: 50px;
}
.title-already .text-already {
  // margin-left: 12px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  // margin-bottom: 0.2rem;
}

.text-name {
  color: #333333;
  font-size: 18px;
  line-height: 27px;
  width: 124px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
}
.text-id {
  color: #4e5969;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 0.2rem;
  width: 124px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.already-text2 {
  display: flex;
  // padding-left: 22px;
  // padding-bottom: 32px;
  height: 53px;
  align-items: center;
  justify-content: center;
  color: #4e5969;
  background: #d4e8ff;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.already-text2 .my-course {
  margin-right: 25px;
  cursor: pointer;
}
.already-text2 .my-order {
  margin-left: 25px;
  cursor: pointer;
}
.already-tostudy {
  width: 240px;
  height: 220px;
  border-radius: 10px;
  padding: 16px;
  background: #ffffff;
}
.already-tostudy .already-img {
  width: 208px;
  height: 120px;
  border-radius: 8px;
  /* background: skyblue; */
  background: url("https://assets.wangxiao.net/images/pcFactory/topNav/frame03.png");
}
.already-tostudy button {
  width: 208px;
  height: 44px;
  border: none;
  border-radius: 36px;
  background: linear-gradient(270deg, #2080f7 0%, #4f9dff 100%);
  color: #ffffff;
  margin: 16px auto;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
