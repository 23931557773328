#globalLoading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background:#e6f4ff; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  font-size: 20px;
  /* opacity: 0.8; */
}
