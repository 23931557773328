.content {
  width: 1200px;
  margin: 0px auto;
}
.all-content {
  width: 1200px;
  margin: 60px auto;
  background-color: white;
  border-radius: 12px;
}

.first-content {
  margin: 0px auto;
}
// 示例数据父盒子
.fa-example-showdata {
  height: 419px;
  position: relative;
  width: 1200px;
}

.fa-example-nodata {
  height: 331px;
  position: relative;
  width: 1200px;
}
// 有无数据图片
.no-image {
  position: relative;
  width: 1200px;
  height: 331px;
  filter: blur(4px);
  border-radius: 12px;
  border-radius: 12px;
  box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.12);
}
.example-image {
  width: 1200px;
  height: 419px;
  position: relative;
  filter: blur(4px);
  border-radius: 12px;
  box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.12);
}

.unlock {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  width: 260px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  border: 2px solid var(---Brand1-4, #f7b2aa);
  background: var(---Brand1-3, #fde8e6);
  z-index: 9;
  color: #e51600;
  font-size: 17px;
  font-weight: 600;
}

.example-data {
  transform: rotate(45deg);
  position: absolute;
  right: 0%;
  top: 32px;
  width: 120px;
  height: 39px;
  border-left: 30px solid transparent; /* 左侧边 */
  border-right: 30px solid transparent; /* 右侧边 */
  border-bottom: 30px solid #efefef; /* 底部边 */
  text-align: center;
  font-size: 13px;
  color: #9fa1a4;
  line-height: 50px;
}

.comp-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  // margin-bottom: 20px;
  padding-top: 24px;
  padding-bottom: 24px;
  .comp-title {
    color: #333333;
    font-size: 24px;
    font-weight: 500;
    font-weight: 600;
  }
  .look-more {
    font-size: 14px;
    color: #4e5969;
    line-height: 34px;
    cursor: pointer;
  }
}

.box-card {
  border-radius: 12px;
  box-shadow: 0 4px 28px 0 #3c70d11f;
  padding:32px;
}
.good {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .good-image {
    width: 280px;
    height: 160px;
    margin-right: 30px;
    border-radius: 8px;
  }
  .good-right {
    color: #333333;
    font-size: 3200px;
    line-height: 40px;
    flex: 1;
    .good-course {
      font-size: 20px;
      font-weight: 700;
    }
    .good-subject {
      font-size: 18px;
      font-weight: 400;
      color: gray;
    }
  }
}

.process {
  margin: 12px 0 0 0;
  background-color: #e3e4e8;
  height: 10px;
  border-radius: 10px;

  position: relative;
  .process-left {
    border-radius: 16px;
    background: linear-gradient(90deg, #ff897c 0%, #e51600 100%);
    height: 10px;
    position: absolute;
  }

  .process-label {
    width: 50px;
    height: 20px;
    background-color: white;
    border-radius: 16px;
    transform: translateY(-6px) translateX(-1px);
    box-shadow: 0 0 12px 0 #0000001f;
    color: #e51600;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
}
.course-box {
  height: 68px;
}
.course {
  background: linear-gradient(to right, #fefbfa, #fef5f5);

  height: 50px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 16px;
  font-weight: 600;
  font-size: 25px;
  padding: 15px 20px 50px 20px;
  position: relative;
  .course-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .course-data {
    display: inline-block;
    vertical-align: middle;
  }
  .course-label {
    padding: 15px 0px;
    border: 2px solid #ee7467;
    display: inline-flex;
    border-radius: 8px;
    width: 50px;
    height: 30px;
    color: #ee7467;
    background-color: #fde8e6;
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
  }

  .light-label {
    padding-bottom: 3px;
    font-weight: 400;
    font-size: 20px;
  }
}

.last {
  color: #4e5969;
  text-shadow: 0 0 6px #0000001f;
  font-size: 20px;
  font-weight: 400;
  line-height: 33.6px;
  margin: 20px 0 8px 0;
}

.bottom {
  padding: 20px 20px;
  border: 2px solid #fde8e6;
  background-color: #fef7f7;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bottom-cotainer {
    display: flex;
    align-items: center;
    .bottom-icon {
      width: 14.67px;
      height: 13.33px;
    }
    h3 {
      margin-bottom: 0;
    }
    .bottom-course {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px;
      margin-left: 10px;
      margin-right: 12px;
    }
  }

  .study-button {
    display: flex;
    width: 80px;
    height: 35px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    border: 2px solid #e51600;
    background-color: #e51600;
    font-size: 16px;
  }
}
