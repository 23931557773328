.video-play-box{
    height: calc(100vh - 68px);
    background: #000000;
    display: flex;
    .video-content-box{
        height: calc(100vh - 68px);
        flex: 1;
        .video-box{
            height: calc(100vh - 68px);  
        }
    }
}
.sign-modal-wrap{
    width: 300px !important;
    height: 320px;
    background:  url("../images/mode_bg.png") 100% 100% no-repeat;
    background-size:cover;
    .ant-modal-content {
        padding: 88px  24px 32px;
        background: transparent;
        box-shadow: none;      
    }

}
.pop-title{
    color: #8c513e;
    font-family: "PingFang SC";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}
.pop-info{
    color: #333333;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin: 20px 0 24px;
}
.pop-bottom{
    display: flex;
    justify-content: space-between;
}
.pop-close{
    display: flex;
    height: 48px;
    width: 122px;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    border: 1px solid  #ECEDF1;
    background:  #FFF;
    cursor: pointer;
}
.pop-srue{
    display: flex;
    height: 48px;
    width: 122px;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    color: #fff;
    background:#E51600;
    cursor: pointer;
}