.loginOutwrap {
  .login-header {
    height: 75px;

    width: 1200px;

    margin: 0 auto;

    line-height: 75px;
  }

  .login-header .left {
    float: left;

    margin-top: 14.5px;

    width: 143px;
  }

  .login-header .right {
    float: right;

    font-size: 16px;

    color: #666666;
  }

  .login-header .right .recordJump {
    color: #4085ed;
  }

  .login-footer {
    height: 144px;

    z-index: 100;

    background: white;

    position: relative;
  }

  .login-footer div {
    width: 1200px;

    position: absolute;

    left: 0;

    right: 0;

    margin: auto;

    top: 50%;

    transform: translateY(-50%);
  }

  .login-footer p {
    text-align: center;

    color: #afb0b5;
  }
}
