.chapter-content-wrap {
  display: flex;
  justify-content: space-evenly;
  padding: 24px 32px;
}
.chapter-progress-wrap {
  position: relative;
  width: 100px;
  height: 100px;
}
.chapter-progress-text {
  width: 100px;
  position: absolute;
  top: 45%;
  left: 120%;
  transform: translate(-120%, -40%);
  text-align: center;
  color:#abacaf;
  .progress-text-add{
    padding-top: 14px;
  }
  .progress-info {
    display: inline; /* 使 div 内容在一行显示 */
  }
  
  .text-color {
    color: #e61d08; /* 设置字体颜色为红色 */
    font-size: 24px;
  }
  .percent-sign {
    color: #4e5969; 
    margin-left: 6px;
  }
}
.chapter-progress-img {
  position: absolute;
  width: 100px;
  height: 100px;
}
.chapter-right-content-wrap {
  width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .chapter-detail-title {
    font-size: 32px;
    font-weight: 500;
    color: #4e4e4e;
  }
  .chapter-bottom-content-wrap {
    display: flex;
    justify-content: space-between;
    .chapter-learned-status {
      display: flex;
      flex-direction: column;
      .doneText {
        font-size: 14px;
        font-weight: 400;
        color:#9FA1A4;
      }
      .doneText-Num{
        font-size: 17px;
        font-weight: 400;
        color:#9FA1A4;
      }
    }
    .chapter-middle-line {
      border-left: 1px solid #eeeeee;
    }
    .chapter-learned-duration {
      display: flex;
      flex-direction: column;
      .totalText {
        font-size: 14px;
        font-weight: 400;
        color:#9FA1A4;
      }
      .totalText-Num{
        font-size: 17px;
        font-weight: 400;
        color:#9FA1A4;
      }
    }
  }
}
.u-progress-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.percent {
  color: #4e5969;
  position: absolute;
  margin-left: 10px;
  font-size: 24px;
  margin-top: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.u-progress-info {
  font-size: 28px;
  // padding-left: 16px;
  padding-left: 5px;
  letter-spacing: 2px;
  color: #9fa1a4;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}