.background-container {
  width: 1200px;
  height: 100vh;
  background: url("https://resource.wangxiao.net/pc/images/signAgreement.png")
    no-repeat center center;
  margin: 0 auto;
}

.form-container {
  position: relative;
  height: 600px;
  padding: 20px;
  background-color: #fff;
  margin: 0 auto;
  text-align: center;
  margin-top: 150px;
}
.secret-line {
  display: flex;
  align-items: center; // 垂直居中对齐子元素
  justify-content: flex-start;
}
.secret-icon {
  width: 12px;
  height: 15px;
}
.secret-text {
  font-size: 12px;
  color: red;
}
.upload-container {
  margin-top: 50px;

  .ant-upload-list-item-container {
    width: 300px !important;
    height: 200px !important;

    img {
      object-fit: cover !important;
    }
  }
}
.upload-img {
  width: 250px;
  height: 160px;
}
.hint-text {
  display: block;
  margin-top: 20px;
  font-size: 12px;
  color: #9ea1a3;
}

.font_5 {
  font-size: 12px;
}

.submit-button {
  position: relative;
  top: 40px;
  color: #fff;
  width: 300px;
  height: 52px;
  padding: 10px 12px;
  font-size: 16px;
  border-radius: 36px;
  background-color: #e51600;
  cursor: pointer;
  border: none;
}
