.info-content {
  background: #fff;
  border-radius: 12px;
}
.course-tab-list {
  width: 100%;
  height: 54px;
  line-height: 54px;
  padding: 0 20px;
  .ant-tabs-tab-btn:hover {
    color: #3c80ef;
  }
}
.course-catalog {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #9fa1a4;
  padding: 0 20px;
  text-align: center;
}
.active {
  color: #3c80ef;
}
.infoList-content {
  // max-height: 424px;
}
.ant-tabs-tab-btn {
  // width: 100px;
  color: rgb(159, 161, 164);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
}
