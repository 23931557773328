.course-list-card {
    background-color: #fff;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0px 40px 0px 40px;
    border-bottom: 1px solid #E5E6EB;
    // border-radius: 8px;
    overflow: hidden;
    // margin-bottom: 16px;
    transition: transform 0.2s ease-in-out;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover{
        .course-list-title{
            color: #3C80EF;
        }
    }
}

.course-list-img {
    width: 164px;
    height: 92px;
    // background-color: red;
    // margin-left: 40px;
}
.recordEmptyBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recordEmptyImg {
    width: 172px;
    height: 172px;
    // background: url("../images/empty.png") 100% 100%;
    background: url("../../../pages/PersonalCenter/images/empty.png") 100% 100%;
}
.course-list-info {
    padding: 20px;
}

.course-list-title {
    color: #333333;
    text-align: justify;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
}

.course-list-use-time {
    color: #9fa1a4ff;
    text-align: justify;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;
}

.course-list-button {
    width: 80px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 36px;
    border: 1px solid #fe4e4799;
    color: #e51600ff;
    font-size: 14px;
    background-color: #fde8e6ff;
    font-family: "PingFang SC";
    &:hover{
        background-color: #e51600;
        color: #fff;
    }
}