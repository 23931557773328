.info-content {
  background: #fff;
  border-radius: 12px;
  
}
.course-tab-list {
  width: 100%;
  height: 54px;
  line-height: 54px;
  padding: 0 20px;
  /* overflow-x: hidden auto;
    white-space: nowrap;
    overflow-y: hidden; */
}
.information-list {
  width: 100%;
  min-height: 1110px;
  margin-bottom: 100px;
}
.course-catalog {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #9fa1a4;
  padding: 0 20px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
}
.active {
  color: #3c80ef;
}
footer {
  margin-top: 60px;
}
