.login-welcome {
  // position: absolute;
  // top: 40px;
  // right: 360px;
  z-index: 2;
  width: 240px;
  height: 384px;
  background: #ffffff;
  border-radius: 10px;
  padding: 46px 16px 40px 16px;
  // box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 28px #3c70d11f;
  margin-left: 24px;
}
.welcome-title1 {
  display: flex;
  margin-bottom: 29px;
  justify-content: center;
  align-items: center;
}
.img {
  min-width: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: url("https://assets.wangxiao.net/images/pcFactory/topNav/defaultavatar.png")
    no-repeat;
  background-size: cover;
}
.welcome-title1 .welcome-text1 {
  margin-left: 12px;
  color: #4d5055;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.welcome-text2 {
  display: flex;
}
.welcome-img {
  width: 208px;
  height: 140px;
  /* background-color: pink; */
  background: url("https://assets.wangxiao.net/images/pcFactory/topNav/frame02.png");
  margin-bottom: 32px;
}
.login-welcome button {
  width: 208px;
  height: 44px;
  border: none;
  border-radius: 36px;
  background: linear-gradient(270deg, #2080f7 0%, #4f9dff 100%);
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background: linear-gradient(270deg, #4dc7f9 0%, #72b0ff 100%);
  }
}

.welcome-title1 .welcome-text1 {
  margin-left: 12px;
  color: #4d5055;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.welcome-name1 {
  margin-bottom: 8px;
}
.welcome-name2 {
  width: 100px;
  margin-bottom: 0;
}
.welcome-text2 {
  display: flex;
}
.welcome-img {
  width: 208px;
  height: 140px;
  background: url("https://assets.wangxiao.net/images/pcFactory/topNav/frame02.png");
  margin-bottom: 32px;
}
.login-welcome button {
  width: 208px;
  height: 44px;
  border: none;
  border-radius: 36px;
  background: linear-gradient(270deg, #2080f7 0%, #4f9dff 100%);
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background: linear-gradient(270deg, #4dc7f9 0%, #72b0ff 100%);
  }
}
