
.live-single-box{
    width: 1200px;
    margin: 0 auto;

    .title-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 32px 0;
    }
    .bord-16 {
      font-size: 24px;
      font-weight: 500;
    }
    .live-sing{
      position:relative;
      width:100%;
      background: #FFFFFF;
      .live-video{
        position: relative;
        padding:12px;
        border-radius:20px;
        background: #3c80ef40;
        box-shadow: 0 2px 14px 0 #3c70d11f;
        overflow: hidden;
        iframe{
          width:100%;
          border-radius:20px;
          height:662px;
          border: none;
        }
      }
    }
    .live-btn{
      position: absolute;
      cursor: pointer;
      top: 50%;
      left: 50%;
      margin-top: -26px;
      margin-left: -98px;
      width: 196px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-size: 20px;
      font-weight: 500;
      color:#3C80EF;
      border: 1px dashed #3C80EF;
      background: rgba(60, 128, 239, 0.25);
      img{
          width: 20px;
          margin-right: 10px;
      }
    }
    .live-btn-lock{
      color:#ffffff;
      margin-left: -123px;
      width: 246px;
      background: rgba(60, 128, 239, 0.4);
    }
    .live-btn:hover{
      background:  #3C80EF;
      color:#ffffff;
    }
  
    .live-cover{
      position: absolute;
      top: 12px;
      left: 12px;
      right: 12px;
      bottom: 12px;
      border-radius:20px;
      background: rgba(0, 0, 0, 0.7);
    }
  
    .tag-status{
      position:absolute;
      top:24px;
      left:24px;
      display: flex;
      height: 28px;
      padding: 0px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 36px;
      background:#3C80EF;
      font-size:12px;
      color:#ffffff;
      z-index: 999;
      img{
        width:12px;
        margin-right:4px;
      }
    //   transform: scale(0.5, 0.5);
    }
    .tag-status-0{
      background:#FE4E47;
    }
    .tag-status-1{
      background: #3C80EF;
    }  
    .tag-status-2{
      background: #9FA1A4;
    }
    .tag-status-3{
      background: #FF7D00;
    }
  
    .ellipsis-two{
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .live-name{
      font-size: 18px;
      font-weight:500;
    }
    .info{
      font-size:16px;
      color:#9FA1A4;
      text-align:right;
    }
    .content-single-box{
      margin-top:18px;
      display:flex;
      justify-content:space-between;
    }
}
