.body-box {
  width: 100%;
  height: 100%;
  position: fixed;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #f9f9f9;
}
.contain {
  text-align: center;
}
.message {
  margin-bottom: 20px;
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.br-thread-line {
  color: #c4c6cb;;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 50px 0 30px 0;
  position: relative;
}
.br-thread-line::before,
.br-thread-line::after {
  content: "";
  position: absolute;
  top: 61%;
  width: 85%;
  height: 1px;
  background-color: #f0f2f5;
}
.br-thread-line::before {
  left: 360px;
  transform: translateY(-50%);
}
.br-thread-line::after {
  right: 360px;
  transform: translateY(-50%);
}
.browsers {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.browser {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.browser img {
  width: auto;
  height: auto;
}
