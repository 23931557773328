.live-list-box {
  width: 1200px;
  margin: 0 auto;
  // overflow: hidden;
}

.title-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
}

.more-button {
  color: #4E5969;
  font-size: 14px;
}
.bord-16 {
  font-size: 24px;
  font-weight: 500;
}
.ellipsis-two {
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gay-color {
  color: #9fa1a4;
}
.red-color {
  color: #fe4e47;
}
.icon-img {
  width: 14px;
  margin-right: 4px;
}
.flex-center {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.tag-status {
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  height: 28px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  background: #3c80ef;
  font-size: 12px;
  color: #ffffff;
  z-index: 999;
  img {
    width: 12px;
    margin-right: 4px;
  }
}
.tag-status-0 {
  background: #fe4e47;
}
.tag-status-1 {
  background: #3c80ef;
}
.tag-status-2 {
  background: #9fa1a4;
}
.tag-status-3 {
  background: #ff7d00;
}
.tag-status-4 {
  background: #3c80ef;
}
.list-layout-0 {
  display: flex;
  flex-wrap: wrap;
  display: flex;
  // overflow:hidden;
  border-radius: 8px;
  .item-box {
    width: 23.5%;
    background: #ffffff;
    margin-bottom: 32px;
    border-radius: 8px;
    box-shadow: 0 4px 28px 0 #3c70d11f;
    overflow: hidden;
    .live-img-box-0 {
      position: relative;
      width: 100%;
      height: 158px;
      // display: flex;
      // justify-content: center;
      .img-bg {
        height: 100%;
        width: 100%;
        // height:194px
      }
    }
    .content-box {
      padding: 16px;
    }
    .live-name {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      height: 36px;
    }
    .time-box {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #333333;
    }
  }
}
.list-layout-1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // overflow:hidden;
  border-radius: 8px;
  .item-box {
    margin-bottom: 32px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 28px 0 #3c70d11f;
    width: 49%;
    // padding:10px 4%;
    background: #ffffff;
    overflow: hidden;
    display: flex;
    .live-img-box-1 {
      position: relative;
      width: 284px;
      height: 158px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      .img-bg {
        width: 100%;
        height: 100%;
      }
    }
    .live-name {
      font-size: 14px;
      line-height: 16px;
      height: 32px;
    }
    .content-box {
      padding: 28px;
      width: 300px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .time-box {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #333333;
      .info {
        font-size: 12px;
      }
    }
  }
}
.list-layout-2 {
  .item-box {
    display: flex;
    align-items: center;
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 32px;
    background: #fff;
    box-shadow: 0 4px 28px 0 #3c70d11f;
    .live-img-box-2 {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 284px;
      height: 158px;
      border-radius: 8px;
      .img-bg {
        width: 100%;
        height: 100%;
      }
    }
    .live-name {
      font-size: 14px;
      line-height: 16px;
      height: 32px;
    }
    .content-box {
      height: 156px;
      padding: 28px;
      width: 916px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .time-box {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #333333;
      .info {
        font-size: 12px;
      }
    }
  }
}
.list-layout-3 {
  width: 100%;
  display: flex;
  white-space: nowrap;
  // overflow: hidden;
  position: relative;
  padding: 20px 0;
  .item-box {
    display: flex;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 28px 0 #3c70d11f;
    min-width: 438px;
    width: 438px;
    background: #ffffff;
    overflow: hidden;
    .live-img-box-3 {
      position: relative;
      width: 216px;
      height: 120px;
      display: flex;
      justify-content: center;
      .img-bg {
        height: 100%;
        width: 100%;
      }
    }
    .live-name {
      font-size: 14px;
      line-height: 16px;
      height: 32px;
    }
    .content-box {
      width: 192px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .time-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 12px;
      color: #333333;
      .info {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
      }
    }
  }
}

.live-btn1 {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -69px;
  width: 138px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #3c80ef;
  border: 1px dashed #3c80ef;
  background: rgba(60, 128, 239, 0.25);
  img {
    width: 14px;
    margin-right: 4px;
  }
}
.live-btn-lock1 {
  color: #ffffff;
  margin-left: -86px;
  width: 172px;
  background: rgba(60, 128, 239, 0.4);
}
.live-btn1:hover {
  background: #3c80ef;
  color: #ffffff;
}
.live-cover1 {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  // border-radius:8px;
  background: rgba(0, 0, 0, 0.7);
}

.left-button {
  display: flex;
  width: 16px;
  height: 28px;
  padding: 6px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px 0 0 4px;
  background: #00000040;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -14px;
  z-index: 999;
  .icon-symbol_left {
    color: #ffffff;
  }
}
.right-button {
  display: flex;
  width: 16px;
  height: 28px;
  padding: 6px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px 0 0 4px;
  background: #00000040;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -14px;
  z-index: 999;
  .icon-symbol_right {
    color: #ffffff;
  }
}
