.container1{
    height: 384px;
    width: 1200px;
    margin: 0px auto;
    border-radius: 12px;
    
    .content{
        height: 384px;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
        }
    }

}