.courseinfoOutwrap {
  .course-info {
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    padding: 20px 20px 30px;
    border-radius: 8px;
  }

  .express-btn-icon-course {
    height: 12px;
    width: 12px;
    margin-left: 5px;
    // margin-top: 5px;
  }
  .retract-btn-icon-course {
    height: 12px;
    width: 12px;
    margin-left: 5px;
    // margin-top: 3px;
  }
  .retract-btn-icon-course-commodity {
    height: 12px;
    width: 12px;
    // margin-left: 5px;
    // margin-top: 3px;
  }
  .course-lf {
    width: 560px;
    position: relative;
  }

  .course-video {
    height: 314px;
    background: #000;
    width: 556px;
    object-fit: cover;
    border-radius: 12px;
  }

  .course-img-pick {
    /* background-color: #07c160; */
    height: 45px;
    margin-top: 16px;
    display: flex;
    /* width: 200px; */
  }

  .pick-img-left {
    color: #9fa1a4;
    line-height: 45px;
    margin-left: 0px;
    margin-right: 8px;
    cursor: pointer;
    font-size: 22px;
  }

  .pick-img-content {
    display: flex;
  }

  .pick-img-right {
    color: #9fa1a4;
    line-height: 45px;
    margin-left: 26px;
    cursor: pointer;
    font-size: 22px;
  }

  .pick-img-one {
    margin-left: 16px;
    width: 80px;
    height: 44px;
    background: #f5f5f5;
    cursor: pointer;
  }

  .pick-img-one-active {
    margin-left: 16px;
    border: 1px solid #fe4e47;
    width: 80px;
    height: 43px;
    cursor: pointer;
  }
  .bg-miaosha {
    background: url("../images/miaosha.png") no-repeat;
    background-size: 100% 100%;
  }
  .bg-group {
    background: url("../images/group.png") no-repeat;
    background-size: 100% 100%;
  }
  .limited-time-group {
    color: #fff;
    font-size: 0;
    height: 80px;
    // border-radius: 12px 12px 12px 12px;
    width: 580px;
    overflow: hidden;
    position: relative;
  }
  .bottom-10 {
    margin-bottom: 10px;
  }
  .group-num {
    position: absolute;
    top: 25px;
    left: 144px;
    height: 18px;
    line-height: 18px;
    background-color: #ffee83;
    color: #8c513e;
    border-radius: 28px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 8px;
    padding-right: 8px;
  }
  .time-box-all {
    width: 189px;
    height: 51px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    right: 30px;
    top: 9px;
  }
  .time-box-all-start {
    width: 189px;
    position: absolute;
    right: 30px;
    top: 23px;
  }
  .time-start {
    height: 20px;
    width: 189px;
    text-align: center;
    font-size: 14px;
    color: #323949;
    margin-top: 1px;
    font-weight: 500;
  }
  .time-box {
    display: flex;
    // margin-top: 15px;
    // margin-right: 30px;
  }

  .time {
    display: inline-block;
    font-size: 18px;
    border-radius: 8px;
    background: #323949;
    width: 27px;
    height: 27px;
    /* margin-top: 15px; */
    line-height: 27px;
    text-align: center;
  }

  .time-icon {
    width: 16px;
    height: 19px;
    color: #323949;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 3px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .time-icon-text {
    width: 14px;
    height: 20px;
    color: #323949;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    margin-top: 3px;
    margin-left: 6px;
    margin-right: 12px;
  }

  .course-info .course-right .price-group {
    /* height: 50px; */
    padding: 16px 20px;
    background: #f7f8fb;
    border-radius: 12px;
    position: relative;
  }

  .price-group-top {
    margin-top: -10px;
  }
  .add-level-box-text {
    width: 580px;
    height: 40px;
    // background: url("../images/add-level-box.png") no-repeat;
    // background-size: 100% 100%;
  }
  .coupon-one {
    /* background-color: yellow; */
    display: flex;
  }

  .coupon-one-top {
    margin-top: 12px;
  }

  .coupon-one-type {
    width: 44px;
    height: 17px;
    border: 1px solid #fe4e47;
    text-align: center;
    line-height: 17px;
    font-size: 12px;
    color: #fe4e47;
    /* padding-left: 4px;
       padding-right: 4px; */
  }

  .coupon-one-title {
    color: #4e5969;
    font-size: 12px;
    height: 17px;
    line-height: 19px;
    margin-left: 16px;
    /* background: red; */
  }

  .coupon-one-btn {
    color: #3c80ef;
    font-size: 12px;
    height: 17px;
    line-height: 19px;
    margin-left: 16px;
    cursor: pointer;
  }
  .gift-one-btn {
    color: #4e5969;
    font-size: 12px;
    height: 17px;
    line-height: 19px;
    margin-left: 16px;
    cursor: pointer;
    .gift-num-text {
      color: #e51600;
    }
  }
  .flex-line-one {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    max-width: 480px;
    gap: 8px;
  }
  .gift-time {
    color: #e44635;
    font-size: 12px;
    margin-top: 8px;
  }
  .gift-good-box {
    height: 56px;
  }
  .gift-coupon-box {
    height: 24px;
  }

  .gift-exchange-box {
    height: 24px;
  }
  .gift-good-one {
    width: 100px;
    height: 56px;
    border-radius: 4px;
    margin-right: 12px;
    flex-shrink: 0;
  }
  .gift-coupon-one {
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    background-color: #ffdac5;
    border-radius: 4px 5px 5px 4px;
    .gift-coupon-one-left {
      width: 52px;
      text-align: center;
      color: #ffffff;
      background: url("../images/coupon-gift.png") no-repeat;
      background-size: 100% 100%;
    }
    .gift-coupon-one-right {
      color: #d75e40;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .gift-exchange-one {
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    background-color: #ffe9cf;
    border-radius: 4px 5px 5px 4px;
    .gift-exchange-one-left {
      width: 52px;
      text-align: center;
      color: #ffffff;
      background: url("../images/exchange-gift.png") no-repeat;
      background-size: 100% 100%;
    }
    .gift-exchange-one-right {
      color: #cd6f15;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .coupon-one-btn-disabled {
    color: #9fa1a4;
    font-size: 12px;
    cursor: pointer;
  }
  .gift-content {
    margin-top: 8px;
    margin-left: 60px;
  }
  .course-info .course-right .open-subject-group {
    box-shadow: 0 0 10px 3px rgba(175, 176, 181, 0.2);
    border-radius: 5px;
    width: 580px;
    z-index: 1;
    background: #fff;
  }

  /* .course-info .course-right .course-info-item .item-lf {
       color: #afb0b5;
       font-size: 12px;
       float: left;
   } */

  .sku-level {
    padding: 20px 10px 0;
  }

  .sku-name {
    /* display: inline-block; */
    /* width: 69px; */
    text-align: left;
    color: #4e5969;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
    /* line-height: 44px; */
  }

  .btn-list {
    cursor: pointer;
  }

  .course-info .course-right .course-info-item .item-lf .btn-list {
    display: flex;
    flex-wrap: wrap;
    width: 560px;
    /* background: red; */
  }

  .course-info .course-right .course-info-item .item-lf .btn-list > span {
    display: inline-block;
    color: #344049;
    font-size: 12px;
    height: 34px;
    padding: 0 24px;
    line-height: 34px;
    background: #f7f8fa;
    border: 1px solid #dbdddf;
    border-radius: 4px;
    position: relative;
    margin-bottom: 10px;
  }

  .course-info
    .course-right
    .course-info-item
    .item-lf
    .btn-list
    > span:not(:last-child) {
    margin-right: 10px;
  }

  .course-info
    .course-right
    .course-info-item
    .item-lf
    .btn-list
    > span.active {
    background: rgba(239, 69, 69, 0.05);
    border: 1px solid #ef4545;
    color: #ef4545;
  }

  /* .course-info .course-right .course-info-item .item-lf .btn-list>span:after { */
  .miaosha:after {
    content: "秒杀";
    display: inline-block;
    width: 36px;
    height: 16px;
    text-align: center;
    /* padding-right: 2px; */
    background: linear-gradient(90deg, #fe8e2a 0%, #fd622c 100%);
    border-radius: 8px 4px 0 8px;
    color: #ffffff;
    font-family: "PingFang SC";
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    background-size: 100% 100%;
    position: absolute;
    top: -0.5px;
    right: -0.5px;
  }
  .group:after {
    content: "拼团";
    display: inline-block;
    width: 36px;
    height: 16px;
    text-align: center;
    /* padding-right: 2px; */
    background: #fe4e47;
    border-radius: 8px 4px 0 8px;
    color: #ffffff;
    font-family: "PingFang SC";
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    background-size: 100% 100%;
    position: absolute;
    top: -0.5px;
    right: -0.5px;
  }
  .xiangou:after {
    content: "售罄";
    display: inline-block;
    width: 36px;
    height: 16px;
    text-align: center;
    /* padding-right: 2px; */
    // background: linear-gradient(90deg, #fe8e2a 0%, #fd622c 100%);
    background-color: #9fa1a4;
    border-radius: 8px 4px 0 8px;
    color: #ffffff;
    font-family: "PingFang SC";
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    background-size: 100% 100%;
    position: absolute;
    top: -0.5px;
    right: -0.5px;
  }
}
.top-16 {
  margin-top: 16px;
}
.left-16 {
  margin-left: 16px;
}
.goods-popover-title-course-detail {
  color: #333333;
  font-size: 14px;
  margin-bottom: 16px;
}
.goods-popover-one-course-detail {
  display: flex;
  .goods-popover-img-course-detail {
    width: 100px;
    height: 56px;
    border-radius: 4px;
  }
  .goods-popover-right {
    margin-left: 16px;
    .goods-popover-name {
      width: 280px;
      color: #333333;
      font-size: 12px;
      height: 34px;
      line-height: 16.8px;
    }
    .goods-popover-skuName {
      color: #9fa1a4;
      font-size: 12px;
      margin-top: 4px;
    }
  }
}
.ellipsis-two {
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
