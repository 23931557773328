.informationBoxHome {
  width: 1200px;
  margin: 0 auto;
}
.informationBox {
  width: 1200px;
  background: linear-gradient(272deg, #c3eaff -3.98%, #e5f0ff 99.85%);
  border-radius: 16px;
  margin: 0 auto;
  padding: 0px 16px 16px 16px;
  // margin-bottom: 60px;
  // margin-top: 40px;
}
.info-top {
  display: flex;
  // height: 34px;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  // margin-bottom: 18px;
}
.info-title {
  // color: #333333;
  font-family: "PingFang SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.more-btn {
  color: #4d86bb;
  text-align: right;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
