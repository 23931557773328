/* 基础样式 */
.my-course-container {
  max-width: 910px;
  min-height: 600px;

  .course-tab-list {
    width: 110%;
    height: 54px;
    line-height: 54px;
    padding: 2px 43px;
    border-bottom: 1px solid #F2F3F5;
  }
}

.tab-item {
  cursor: pointer;
  padding: 10px 20px;
  display: inline-block;
  margin-right: 10px;
  color: #333333;
  border-bottom: 2px solid transparent;
  position: relative;
}

.tab-item.active {
  color: #3c80ef;
  // width: 4px;
  //   height: 40px;
  //   transform: rotate(-90deg);
  //   border-radius: 2px;
  //   background: var(--pc, #3C80EF);
}

.tab-item.active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
  width: 60%;
  height: 2px;
  background-color: #3c80ef;
}

/* 鼠标悬停的样式 */
.tab-item:hover::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
  width: 60%;
  height: 2px;
  background-color: #3c80ef;
  transition: background-color 0.3s;
}

.course-card {
  background-color: #fff;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 42px 40px 0 40px;
  border-bottom: 1px solid #e5e6eb;
  // border-radius: 8px;
  overflow: hidden;
  // margin-bottom: 16px;
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 42px;
}

.course-card:hover {
  transform: translateY(-5px);
  .course-title {
    color: #3c8def;
  }
}

.course-img {
  width: 262px;
  height: 148px;
  border-radius: 8px;
  // background-color: red;
  // margin-left: 40px;
}

.course-info {
  padding-left: 24px;
}

.course-title {
  color: #333333;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
}

.course-use-time {
  color: #9fa1a4ff;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
}

.course-button {
  width: 96px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 36px;
  border: 1px solid #fde8e6ff;
  color: #e51600ff;
  font-size: 14px;
  background-color: #fde8e6ff;
  font-family: "PingFang SC";
  margin-top:55px;
  &:hover {
    background-color: #e51600;
    color: #fff;
  }
}

.course-empty {
  height: 549px;
  img {
    width: 172px;
    height: 172px;
    // background-color: red;
    // margin-top: 120px;
    margin: 120px 405px 0px 405px;
  }
  .course-emptyText {
    color: #9fa1a4;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0 400px;
  }
  button {
    width: 160px;
    height: 40px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 36px;
    background: var(---Brand1-7, #e51600);
    border: none;
    color: #ffffff;
    margin: 24px 405px 0px 405px;
  }
}

// .pagination-controls {
//   text-align: center;
//   margin-top: 20px;
//   padding-bottom: 20px;
// }

// .pagination-controls {
//   button {
//     padding: 5px 10px;
//     margin: 0 10px;
//     cursor: pointer;
//     color: #333333;
//     background: #FFFFFF;
//     border-radius: 5px;
//     border: 2px solid #9fa1a4;
//   }
//   span {
//     color: #333333;
//     font-weight: 500;
//   }
// }

// .pagination-controls {
//   button:disabled {
//     cursor: not-allowed;
//     opacity: 0.5;
//   }
// }

@media (max-width: 768px) {
  .course-card {
    margin-bottom: 12px;
  }

  .course-info {
    padding: 15px;
  }
}
