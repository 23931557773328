.loadButton {
  width: 52px;
  height: 18px !important;
  border-radius: 10px !important;
  border: 0.5px solid #b47a0e;
  background: #fff;
  right: 1240px;
  position: absolute;
  font-size: 10px !important;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 14px;
    color: #b47a0e;
  }
}
