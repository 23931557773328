.information-content-outwrap {
  background: #f5f6f8;
  padding-bottom: 60px;
  .informationBox {
    width: 1200px;
    background: linear-gradient(272deg, #c3eaff -3.98%, #e5f0ff 99.85%);
    height: 566px;
    border-radius: 16px;
    margin: 0 auto;
    padding: 20px 16px 16px 16px;
  }
  .info-top {
    display: flex;
    height: 34px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .info-top .info-title {
    color: #333333;
    font-family: "PingFang SC";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .content {
    width: 1200px;
    margin: 0 auto;
  }
}
