.header-outwrap-top{
  width: 100%;
  height: 98px;

  .header-content{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-wrap{
      width: 70px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header-logo{
        height: 50px;
        margin-bottom: 10px;

      }
      .header-home{
        height: 50px;
        width: auto;

      }

    }
    .header-input {
      width: 282px;
      .ant-select-selector {
        border: 1px solid #e5e6eb;
      }
    }

    .header-right{
      position: relative;
      margin: 0;
      width: 317px;
      height: 36px;
      display: flex;
      align-items: center;
      font-size: 14px;
      .header-phone {
        width: 86px;
        height: 22px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover{
          color: #E51600;
        }
      }

      .phone-icon {
        width: 16px;
        height: 20px;
        margin: 1px 3px;
        background: pink;
      }
      .phone-text {
        width: 56px;
        height: 20px;
        margin-left: 8px;
      }
      .header-login {
        display: flex;
        margin-left: 90px;
        width: 102px;
        height: 22px;
        line-height: 22px;
      }
      .login-item {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .login-icon {
        width: 22px;
        height: 22px;
        background-color: blue;
      }
      .login-btn {
        margin: 0 6px;
      }
      .register-btn {
        margin-left: 6px;
        cursor: pointer;
      }
      .header-adylogin {
        display: flex;
        align-items: center;
        margin-left: 90px;
        width: 141px;
        height: 36px;
      }
      .header-adylogin .header-user {
        display: flex;
        align-items: center;
      }
      .header-adylogin .header-user .user-avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;
      }
      .header-adylogin .header-user .user-name {
        color: #4d5055;
        font-family: "PingFang SC";
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        margin: 0 4px;
      }
      .header-adylogin .down-icon {
        width: 12px;
        height: 12px;
      }
      .header-adylogin .menu-dropdown {
        height: 36px;
        line-height: 36px;
      }
      .icon-search::before {
        color: #cacdd5;
      }
    }
  }
  
}
.header-modal-wrap .ant-modal-content {
  width: 404px !important;
  height: 460px;
  padding: 60px 28px 50px 28px;
}