.order-my-content {
  min-height: 550px;
  .order-tab-list {
    width: 100%;
    height: 54px;
    line-height: 54px;
    padding: 2px 43px;
    border-bottom: 1px solid #F2F3F5;

  }
  
  .order-content {
    padding: 20px;
  }
  .order-header {
    height: 44px;
    background: #f5f5f5;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .order-item {
    margin-top: 16px;
    border: 1px solid #f2f3f5;
  }
  .order-color-gray {
    color: #9fa1a4;
  }
  .order-min-font {
    font-size: 12px;
  }
  .order-item-info {
    color: #4e5969;
    font-size: 12px;
    height: 38px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .add-level-title {
      width: 88px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 4px;
      background: var(---Brand1-3, #fde8e6);
      margin-right: 16px;
      color: #fe4e47;
      font-size: 12px;
    }
  }
  .order-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .order-goods {
    display: flex;
    align-items: center;
    padding: 12px;
  }
  .order-goods-img {
    width: 128px;
    height: 72px;
    object-fit: contain;
    margin-right: 16px;
  }
  .order-border-bottom {
    border-bottom: 1px solid #f2f3f5;
  }
  .order-border-right {
    border-right: 1px solid #f2f3f5;
  }
  .order-count-down {
    color: #fe4e47;
  }
  .ant-pagination {
    text-align: center;
    margin-top: 30px;
  }
  .pay-btn-order {
    color: #fe4e47;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #e51600;
    }
  }
  .other-btn-order {
    color: #4e5969;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #3c80ef;
    }
  }
}
