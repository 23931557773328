.pay-order-result-wrap {
  width: 1200px;
  margin: 0 auto;
  .order-list-wrap {
    // padding: 20px;
    border: 1px solid #e5e6eb;
    background-color: #ffffff;
    margin-top: 40px;
  }
  .pay-order-goods-table {
    // margin-top: 40px;
    margin: 0 20px;
    .list-line-price {
      color: #9fa1a4;
    }
    th.paymentstatus-title {
      background-color: #f5f5f5;
      color: #333333;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .purchase-table-title {
    height: 57px;
    line-height: 57px;
    font-size: 18px;
    // margin: -16px -16px;
    padding: 0 20px;
    border-bottom: 1px solid #e5e6eb;
  }
  .purchase-info-wrap {
    display: flex;
    justify-content: space-between;
    margin: 30px 22px;
    .order-relative-info {
      color: #9fa1a4;
      font-size: 14px;
      font-weight: 400;
      margin-right: 12px;
    }
    .copy-btn {
      width: 60px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      border-radius: 18px;
      background: #f2f3f5;
      color: #4b538a;
      cursor: pointer;
      padding: 4px 16px;
    }
  }
  .pay-status-header-wrap {
    display: flex;
    justify-content: center;
    margin: 40px 0;
    .pay-status-img {
      img {
        width: 80px;
        height: 80px;
      }
    }
    .pay-status-text-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;
      .pay-status-text {
        color: #323949;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
      }
      .pay-status-tip {
        color: #9fa1a4;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  .goods-list-name-wrap {
    display: flex;
  }
  .goods-list-name {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .goods-list-name1 {
      display: flex;
      justify-content: start;
    }
    .goods-list-skuname1 {
      display: flex;
      justify-content: start;
      color: #9fa1a4;
    }
  }
  .order-amount-info {
    text-align: right;
    padding: 30px 20px;
    .common-price {
      // margin-left: 120px;
      display: inline-block;
      width: 200px;
    }
    .discount-price-wrap {
      margin: 10px 0;
      .discount-price {
        color: #fe4e47;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .order-tip {
    text-align: right;
    margin: 20px 0;
    color: #9fa1a4;
  }
  .goto-study-btn-wrap {
    display: flex;
    justify-content: end;
    margin-bottom: 60px;
    .goto-study-btn {
      display: flex;
      width: 150px;
      height: 50px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #fe4e47;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
