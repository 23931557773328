.activity-page {
  width: 1200px;
  margin: 0 auto;
  .activity-page-banner {
    width: 1200px;
    height: 168px;
    margin-top: 36px;
    padding-top: 24px;
    .page-banner-content {
      height: 120px;
      width: 1200px;
      position: relative;
      .activity-banner-box {
        width: 1200px;
        height: 120px;
        cursor: pointer;
      }
      .banner-content-box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .banner-content-title {
          display: flex;
          justify-content: center;
          color: #ffffff;
          font-size: 28px;
          line-height: 28px;
          font-weight: 500;
          margin-top: 3px;
          .banner-content-title-num {
            font-size: 32px;
            color: #ffd600;
            font-weight: 600;
            margin-left: 8px;
            margin-right: 8px;
          }
        }
        .banner-content-title-replace {
          display: flex;
          justify-content: center;
          color: #50362c;
          font-size: 28px;
          line-height: 28px;
          font-weight: 500;
          margin-top: 3px;
          .banner-content-title-num {
            font-size: 32px;
            color: #ff7d00;
            font-weight: 600;
            margin-left: 8px;
            margin-right: 8px;
          }
        }
        .banner-content-time {
          display: flex;
          color: #ffffff;
          font-size: 20px;
          margin-top: 11px;
          font-weight: 500;
          .banner-time-box {
            width: 36px;
            height: 32px;
            text-align: center;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.2);
          }
          .banner-time-day {
            margin-left: 24px;
            margin-right: 8px;
          }
          .banner-time-hour {
            margin-left: 24px;
            margin-right: 10px;
          }
          .banner-time-min {
            margin-left: 10px;
            margin-right: 10px;
          }
          .banner-time-second {
            margin-left: 10px;
          }
        }
        .banner-content-time-replace {
          display: flex;
          color: #50362b;
          font-size: 20px;
          margin-top: 11px;
          font-weight: 500;
          .banner-time-box {
            width: 36px;
            height: 32px;
            text-align: center;
            border-radius: 8px;
            background: rgba(80, 54, 43, 0.1);
          }
          .banner-time-day {
            margin-left: 24px;
            margin-right: 8px;
          }
          .banner-time-hour {
            margin-left: 24px;
            margin-right: 10px;
          }
          .banner-time-min {
            margin-left: 10px;
            margin-right: 10px;
          }
          .banner-time-second {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .activity-page-goods {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 1225px;
    margin-top: 8px;
    // height: 1110px;
    .allcourse-item {
      width: 282px;
      height: 350px;
      margin-right: 24px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0 4px 28px 0 #3c70d11f;
      margin-bottom: 32px;
      //   float: left;
      cursor: pointer;

      .item-imgs {
        width: 100%;
        height: 158px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        position: relative;
        // border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          // border-radius: 8px;
        }
        .sellingnumber {
          position: absolute;
          width: 102px;
          border-radius: 12px 0 0 12px;
          background: #ff6d1b;
          font-size: 8px;
          text-align: center;
          height: 20px;
          line-height: 20px;
          color: #fff;
          right: 0;
          bottom: 0;
        }
      }
      .goods-card-bottom {
        padding: 0px 16px 20px 16px;
        width: 282px;
        background: #fff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-top: 18px;
        .item-name {
          color: #333333;
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          overflow: hidden;
          width: 250px;
          height: 44px;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
        }
        .item-sellpoint {
          color: #9fa1a4;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          width: 250px;
          margin-top: 8px;
          min-height: 18px;
        }
      }
      .item-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        width: 250px;
        height: 52px;
        .item-teacherList {
          display: flex;
          // justify-content: space-between;
          flex: 1 1;
          .teacher-item {
            width: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 20px;
            img {
              width: 30px;
              height: 30px;
              border-radius: 15px;
            }
            p {
              color: #9fa1a4;
              text-align: center;
              font-family: "PingFang SC";
              font-size: 10px;
              font-style: normal;
              width: 30px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: 400;
            }
          }
        }
        .item-pricebox {
          display: flex;
          flex-direction: column;
          // justify-content: space-around;
          text-align: right;
          margin-top: -7px;
        }
        .add-level-type-0 {
          color: #fe4e47;
          font-size: 12px;
          font-weight: 500;
        }
        .add-level-type-1 {
          color: #fe4e47;
          font-size: 12px;
          font-weight: 500;
          margin-top: 18px;
        }
        .add-level-type-0-num {
          height: 16px;
          padding-left: 4px;
          padding-right: 4px;
          line-height: 16px;
          color: #e51600;
          font-size: 10px;
          border-radius: 4px;
          background: var(---Brand1-3, #fde8e6);
          margin-top: 3px;
        }
        .bottom-line-real {
          display: flex;
          justify-content: flex-end;
        }
        .item-lineprice {
          color: #999eb5;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          margin-top: 9px;
          text-decoration: line-through;
          margin-right: 6px;
        }
        .price-icon {
          color: #fe4e47;
          text-align: right;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          margin-right: 1px;
        }
        .item-realPrice {
          color: #fe4e47;
          text-align: right;
          font-family: "PingFang SC";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          margin-top: 5px;
        }
      }
    }
  }
  .line-one {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
