.group-invite-box {
  width: 1200px;
  border: 1px solid var(---fill-3, #e5e6eb);
  background: #fff;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-bottom: 60px;
  .invite-text {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    .invite-text-blue {
      color: #475ffd;
      margin-left: 3px;
      margin-right: 3px;
    }
  }
  .invite-time-box {
    margin-right: 3px;
    .invite-time-box-one {
      height: 16px;
      border-radius: 4px;
      background: var(---Text-6, #333);
      padding: 0px 5px;
      color: #ffffff;
      font-family: "SF Pro Text";
      font-size: 12px;
      margin-top: 4px;
    }
    .invite-time-box-one-icon {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
  .invite-second {
    margin-top: 8px;
  }
  .invite-user-box {
    margin-top: 24px;
    .invite-user-img {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      background-size: 100% 100%;
      position: relative;
      .group-owner {
        width: 32px;
        height: 16px;
        border-radius: 8px;
        background: var(---Text-6, #333);
        font-size: 10px;
        text-align: center;
        line-height: 16px;
        color: #ffffff;
        position: absolute;
        left: 8px;
        bottom: -4px;
      }
    }
    .invite-user-img-other {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      background-size: 100% 100%;
      margin-left: 32px;
      background: url("../imgs/group-user-one.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .invite-user-img-other-last {
      width: 48px;
      height: 48px;
      // border-radius: 48px;
      background: url("../imgs/group-user-last.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .left-32 {
      margin-left: 32px;
    }
    .left-24 {
      margin-left: 24px;
    }
    .invite-center-icon {
      color: #989ead;
      font-size: 16px;
      font-weight: 600;
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 10px;
    }
  }
  .invite-btn-box {
    margin-top: 28px;
    gap: 24px;
    .invite-btn-left {
      width: 228px;
      height: 57px;
      border-radius: 4px;
      background: #fe4e47;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      line-height: 57px;
      cursor: pointer;
    }
    .invite-btn-right {
      width: 228px;
      height: 57px;
      border-radius: 4px;
      border: 1px solid #ecedf1;
      background: #ecedf1;
      color: #333333;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      line-height: 57px;
      cursor: pointer;
    }
  }
}
