.advertisement {
  box-shadow: 0 4px 28px rgba(60, 112, 209, 0.1215686275);
  width: 614px;
  height: 384px;
  // margin: 16px;
  background: #ffffff;
  border-radius: 0 12px 12px 0;
  position: relative;
  :global(
      .ant-carousel .slick-prev,
      .ant-carousel .slick-next,
      .ant-carousel .slick-prev:hover,
      .ant-carousel .slick-next:hover
    ) {
    font-size: inherit;
    color: currentColor;
  }

  .btnLeft {
    position: absolute;
    top: 50%;
    left: 15px;
    color: #e9e2e2e0;
    font-size: 25px;
    z-index: 1;
    cursor: pointer;
  }

  .btnRight {
    position: absolute;
    top: 50%;
    right: 15px;
    color: #e9e2e2e0;
    font-size: 25px;
    z-index: 1;
    cursor: pointer;
  }
  .advertisementImg {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  }
}
// .ant-carousel {
//   :global {
//     .slick-dots {
//       bottom: 30px;
//     }
//     .slick-dots-bottom {
//       bottom: 30px;
//     }
//   }
// }
.ant-carousel .slick-dots {
  height: 20px !important ;
}
.ant-carousel .slick-dots-bottom {
  bottom: 30px;
}
.ant-carousel .slick-slider .slick-dots {
  bottom: 20px;
}
