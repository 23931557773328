.orderSettlementOutWrap {
  // min-height: 100vh;
  background: #f6f7f9;
  .orderSettleMentWrap {
    width: 1200px;
    margin: 0 auto;
  }
  .page-title {
    color: #4e5969;
    font-size: 24px;
    line-height: 24px;
    padding-left: 20px;
    padding-top: 23px;
    margin-bottom: 23px;
  }
  .order-info-box {
    width: 1200px;
    background-color: #ffffff;
    border: 1px solid #e5e6eb;
    margin: 0px auto;
  }

  .order-info-title {
    height: 57px;
    line-height: 57px;
    padding-left: 20px;
    font-size: 18px;
    border-bottom: 1px solid #e5e6eb;
  }
  .order-info-content {
    padding: 28px 20px;
  }
  .order-info-time {
    font-size: 14px;
    color: #333333;
    line-height: 24px;
  }
  .order-info-time-val {
    color: #9fa1a4;
    font-size: 14px;
    margin-left: 8px;
  }
  .order-info-num-box {
    display: flex;
  }
  .order-info-num {
    font-size: 14px;
    color: #333333;
    line-height: 24px;
  }
  .order-info-num-val {
    font-size: 14px;
    color: #9fa1a4;
    margin-left: 12px;
  }
  .copy-btn {
    width: 60px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 18px;
    background: #f2f3f5;
    color: #4b538a;
    cursor: pointer;
    margin-left: 12px;
  }
  //   .order-info-table {
  //     // margin-top: 22px;
  //   }
  .order-info-header {
    height: 44px;
    background: #f5f5f5;
    line-height: 44px;
    text-align: center;
  }
  .order-info-item {
    border: 1px solid #f2f3f5;
  }
  .order-info-border-right {
    border-right: 1px solid #f2f3f5;
  }
  .order-info-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .order-info-commodity {
    display: flex;
  }
  .order-info-img {
    width: 128px;
    height: 72px;
    margin-top: 12px;
    margin-left: 12px;
    margin-bottom: 12px;
  }
  .order-commodity-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
  }
  .commodity-name {
    color: #333333;
    font-size: 14px;
    max-width: 280px;
  }
  .commodity-skuName {
    font-size: 12px;
    color: #9fa1a4;
    line-height: 12px;
    margin-top: 4px;
  }

  // 优惠券
  .counp-box {
    width: 1200px;
    background-color: #ffffff;
    border: 1px solid #e5e6eb;
    margin: 24px auto;
  }
  .box-title {
    height: 57px;
    line-height: 57px;
    padding-left: 20px;
    font-size: 18px;
    border-bottom: 1px solid #e5e6eb;
  }
  .box-content {
    padding: 0px 20px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 24px;
  }
  .counp-one {
    width: 260px;
    height: 104px;
    border-radius: 8px;
    border: 1px solid #f6f6f6;
    position: relative;
    margin-top: 24px;
    cursor: pointer;
    // margin-right: 32px;
  }
  .check-coupn-icon {
    position: absolute;
    right: -1px;
    bottom: 0px;
    width: 28px;
    height: 28px;
    font-size: 28px;
    color: #f46748;
  }
  .counp-one-dis {
    width: 260px;
    height: 126px;
    border-radius: 8px;
    border: 1px solid #f6f6f6;
    margin-top: 24px;
    // margin-right: 32px;
  }
  .counp-one-right {
    margin-right: 32px;
  }
  .counp-top {
    width: 260px;
    height: 72px;
    border-radius: 8px 8px 0 0;
    background: linear-gradient(270deg, #ffb389 -3.85%, #f96f50 101.92%);
  }
  .counp-top-dis {
    width: 260px;
    height: 72px;
    border-radius: 8px 8px 0 0;
    background: linear-gradient(270deg, #e1e1e1 -3.85%, #c2c2c2 101.92%);
  }
  .coupon-content {
    display: flex;
    // margin-top: 12px;
  }
  .coupon-discount {
    margin-left: 20px;
    margin-top: 12px;
    display: flex;
  }
  .discount-unit {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    margin-top: 9px;
  }
  .discount-val {
    color: #ffffff;
    font-size: 28px;
    line-height: 28px;
    font-weight: 500;
  }
  .discount-unit-after {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #ffffff;
    margin-top: 12px;
  }
  .coupon-requirement {
    color: #ffffff;
    font-size: 12px;
    line-height: 12px;
    margin-left: 8px;
    margin-top: 25px;
  }
  .coupon-useTime {
    color: #ffffff;
    font-size: 12px;
    line-height: 12px;
    margin-left: 20px;
    margin-top: 5px;
  }
  .counp-bottom {
    width: 260px;
    height: 32px;
    line-height: 32px;
    border-radius: 0 0 8px 8px;
    display: flex;
    // justify-content: space-between;
  }
  .counp-bottom-dis {
    width: 260px;
    height: 32px;
    line-height: 32px;
    display: flex;
    // justify-content: space-between;
  }
  .counp-name {
    color: #333333;
    font-size: 14px;
    margin-left: 20px;
    width: 140px;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .counp-ex {
    color: #989ead;
    font-size: 12px;
    display: flex;
    margin-left: 16px;
  }
  .ex-icon {
    width: 10px;
    height: 10px;
    font-size: 10px;
    margin-left: 5px;
    margin-top: 2px;
  }
  .active-counp {
    border: 1px solid #f46748;
  }
  .dis-reason-box {
    width: 260px;
    background: #f7f8fa;
    height: 22px;
    line-height: 22px;
    padding-left: 20px;
    display: flex;
  }
  .reason-title {
    color: #f0522f;
    font-size: 10px;
  }
  .reason-val {
    color: #323949;
    font-size: 10px;
    margin-left: 8px;
  }
  .settlement-box {
    width: 1200px;
    height: 256px;
    border: 1px solid #e5e6eb;
    background: #fff; 
    margin-top: 24px;
  }
  .right-box {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
  .right-box-on-padding {
    display: flex;
    justify-content: flex-end;
  }
  .price-box {
    // height: 130px;
    // width: 1200px;
    padding-bottom: 16px;
  }
  .price-level-1 {
    display: flex;
    margin-top: 24px;
  }
  .price-level-2 {
    display: flex;
    margin-top: 8px;
  }
  .price-discont {
    color: #fe4e47;
    font-size: 14px;
  }
  .price-real-text {
    margin-top: 16px;
  }
  .price-real-box {
    display: flex;
  }
  .price-real {
    color: #fe4e47;
    font-size: 32px;
    font-weight: 600;
  }
  .price-real-unit {
    color: #fe4e47;
    font-size: 16px;
    font-weight: 500;
    margin-top: 16px;
  }
  .good-nums-box {
    display: flex;
  }
  .good-nums-val {
    color: #fe4e47;
    margin-right: 4px;
  }
  .price-show {
    display: flex;
    justify-content: space-between;
    width: 258px;
    color: #333333;
  }
  .border-top {
    border-top: 1px solid #e5e6eb;
  }
  .btn-box {
    height: 126px;
    // width: 1200px;
  }
  .constact-box {
    margin-top: 18.5px;
    color: #9fa1a4;
    font-weight: 500;
    font-size: 12px;
  }
  .uncheck-icon {
    width: 16px;
    height: 16px;
    font-size: 16px;
    margin-top: 19px;
    margin-right: 8px;
    cursor: pointer;
  }
  .check-icon {
    width: 16px;
    height: 16px;
    font-size: 16px;
    margin-top: 19px;
    color: #e51600;
    cursor: pointer;
    margin-right: 8px;
  }
  .constract-link {
    color: #4b538a;
    font-size: 12px;
    font-weight: 500;
  }
  .pay-btn {
    width: 150px;
    height: 50px;
    border-radius: 4px;
    background: #fe4e47;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    margin-top: 18.5px;
    cursor: pointer;
    &:hover{
      background:#fe716b;
    }
  }
}

//
.ex-madal {
  .ant-modal-content {
    background-color: inherit;
    padding: 0px;
    .ant-modal-header {
      background-color: inherit;
    }
  }
}
.ex-madal-title {
  width: 253px;
  height: 24px;
  color: #323949;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
}
.ex-btn {
  display: flex;
  width: 253px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  background: #e51600;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 8px;
  cursor: pointer;
}
.ex-content {
  margin-top: 8px;
  width: 253px;
  color: #323949;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 16px;
  line-height: 28px;
  min-height: 56px;
}
.bottom-box-zhanwei {
  height: 60px;
  width: 100%;
}
.contract-box {
  display: flex;
  justify-content: flex-end;
}
.constrct-all {
  display: flex;
  width: 282px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.constract-btn-box {
  display: flex;
  cursor: pointer;
}
.status-text {
  color: #4b538a;
  font-size: 14px;
}
.consract-icon {
  width: 12px;
  height: 12px;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 4px;
}
.gray {
  color: #9fa1a4;
}
