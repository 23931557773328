.exchangecodeOutwrap {
  height: 600px;
  .recordBox {
    height: 100%;
  }
  .course-tab-list{
    width: 100%;
    height: 54px;
    line-height: 54px;
    padding: 2px 42px;
    border-bottom: 1px solid #F2F3F5;
  }
  .exchange-content {
    padding-left: 40px;
    height: 100%;
  }
  .inputbox {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .code-input {
    width: 348px;
    height: 42px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid #979797;
    margin-right: 32px;
    padding-left: 20px;
  }
  .exchangebutton {
    width: 220px;
    height: 42px;
    border-radius: 2px;
    background: #fe4e47;
    color: #fff;
    text-align: center;
    line-height: 42px;
    &:hover{
      background:#fe716b ;
    }
  }
  .tipTit {
    color: #4e5969;
    text-align: justify;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 14px;
  }
  .tips {
    color: #9fa1a4;
    text-align: justify;
    font-family: "PingFang SC";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.4px;
  }
  .emptyBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .emptyImg {
    width: 172px;
    height: 172px;
    background: url("../images/empty.png") 100% 100%;
  }
  .tipsModal {
    text-align: center;
    display: flex;
    justify-content: center;
    height: 196px;
  }
}
