.order-settlement-gift {
  width: 1200px;
  background-color: #ffffff;
  border: 1px solid #e5e6eb;
  margin: 24px auto;
  .box-title-gift {
    height: 57px;
    line-height: 57px;
    padding-left: 20px;
    font-size: 18px;
    border-bottom: 1px solid #e5e6eb;
    .box-title-gift-nums {
      color: #e51600;
      margin-left: 4px;
      margin-right: 4px;
    }
  }
  .box-content-gift {
    padding: 35px 20px 32px;
    // padding-bottom: 32px;
    .order-info-header {
      height: 44px;
      background: #f5f5f5;
      line-height: 44px;
      text-align: center;
    }
    .gift-exchange-order-box {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      .gift-exchange-order-one {
        height: 28px;
        line-height: 28px;
        font-size: 12px;
        background-color: #ffe9cf;
        border-radius: 4px 5px 5px 4px;
        .gift-exchange-order-one-left {
          width: 66px;
          text-align: center;
          color: #ffffff;
          background: url("../imgs/exchange-gift.png") no-repeat;
          background-size: 100% 100%;
        }
        .gift-exchange-order-one-right {
          color: #cd6f15;
          margin-left: 10px;
          margin-right: 10px;
          max-width: 498px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          -webkit-user-select: text;
          -moz-user-select: text;
          -ms-user-select: text;
          user-select: text;
        }
      }
    }
    .gift-coupon-order-box {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      .gift-coupon-order-one {
        height: 28px;
        line-height: 28px;
        font-size: 12px;
        background-color: #ffdac5;
        border-radius: 4px 5px 5px 4px;
        .gift-coupon-order-one-left {
          width: 66px;
          text-align: center;
          color: #ffffff;
          background: url("../imgs/coupon-gift.png") no-repeat;
          background-size: 100% 100%;
        }
        .gift-coupon-order-one-right {
          color: #d75e40;
          margin-left: 10px;
          margin-right: 10px;
          max-width: 498px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .top-32 {
      margin-top: 32px;
    }
  }
}
