.float-content {
  position: fixed;
  bottom: 200px;
  right: 50px;
  z-index: 99;
  display: flex;
}
.float-phone {
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #00000014;
  padding: 8px 17px 8px 12px;
  margin: 23px 10px 0px 0px;
  font-size: 14px;
  color: #4e5969;
  width: 210px;
  height: 36px;
}
.float-list {
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #00000014;
}
.float-item {
  padding: 16px 8px;
  text-align: center;
  font-size: 12px;
  color: #4e5969;
}

.float-item-first{
  border-bottom: 1px solid #e6e6e7;
}

.float-item-first:hover {
  color: #3c80ef;
}
.float-item-second {
  border-bottom: 1px solid #e6e6e7;
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 7px 4px;
  img{
    width: 65px;
    height: 65px;
  }
}
// .show-chat {
//   z-index: 2147483646;
//   position: fixed;
//   width: 408px;
//   height: 510px;
//   /* box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 25px 0px; */
//   /* bottom: 10px; */
//   /* right: 20px; */
//   left: 640.5px;
//   top: 186px;
//   border-radius: 4px;
//   border: none;
//   background: transparent;
// }
// #MANTIS-CHAT-DIV {
//   z-index: 2147483646;
//   position: fixed;
//   width: 639px;
//   height: 510px;
//   box-shadow: 0;
//   /* bottom: 10px; */
//   /* right: 20px; */
//   left: 640.5px;
//   top: 186px;
//   bottom: 0px;
//   right: 0px;
//   border-radius: 4px;
// }
