.agreement-my-content {
  min-height: 550px;
  .agreement-tab-list {
    width: 100%;
    height: 54px;
    line-height: 54px;
    padding: 2px 42px;
    border-bottom: 1px solid #F2F3F5;
  }

  .agreement-content {
    padding: 32px 40px;
  }
  .agreement-item {
    margin-bottom: 24px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid #f2f3f5;
    background-color: #f7f8fa;
    .agreement-fl {
      margin-left: 21px;
      margin-right: 10px;
    }
    .agreement-fr {
      margin-right: 20px;
    }
    .icon-myagreement {
      font-size: 19px;
      color: #ad724a;
    }
    .agreement-itemTitle {
      font-size: 16px;
      color: #4e5969;
      margin-right: auto;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
    }
  }
  .agreement-color-gray {
    color: #9fa1a4;
  }
  .agreement-min-font {
    font-size: 12px;
  }
  .agreement-item-info {
    color: #4e5969;
    font-size: 12px;
    height: 38px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
  }
  .agreement-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .agreement-goods {
    display: flex;
    align-items: center;
    padding: 12px;
  }
  .agreement-goods-img {
    width: 128px;
    height: 72px;
    object-fit: contain;
    margin-right: 16px;
  }
  .agreement-border-bottom {
    border-bottom: 1px solid #f2f3f5;
  }
  .agreement-border-right {
    border-right: 1px solid #f2f3f5;
  }
  .agreement-count-down {
    color: #fe4e47;
  }
  .ant-pagination {
    text-align: center;
    margin-top: 30px;
  }
}
