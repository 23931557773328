.status-box {
  display: flex;
  padding-top: 40px;
  height: 120px;
  width: 358px;
  // background-color: red;
  margin: 0 auto;
}
.status-icon {
  width: 80px;
  height: 80px;
}
.status-0 {
  background: url(../images/awaitPay.png) no-repeat;
  background-size: 100% 100%;
}
.status-1 {
  background: url(../images/afterSale.png) no-repeat;
  background-size: 100% 100%;
}
.status-2 {
  background: url(../images/close.png) no-repeat;
  background-size: 100% 100%;
}
.status-3 {
  background: url(../images/group.png) no-repeat;
  background-size: 100% 100%;
}
.status-title {
  color: #323949;
  font-weight: 600;
  margin-top: 12px;
  margin-left: 20px;
  font-size: 20px;
}
.status-subTitle {
  color: #9fa1a4;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 4px;
}