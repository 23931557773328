.contentStyle {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #e5e6eb;
  padding-bottom: 36px;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  @media screen and (min-height: 500px) and (max-height: 1000px) {
    //竖屏
    @media (orientation: portrait) {
      .contentStyle {
        min-height: 120vh;
      }
    }
    //横屏 
    @media (orientation: landscape) {
      .contentStyle {
        min-height: 163vh;
      }
    }
    // .contentStyle {
    //   min-height: 120vh;
    // }
  }
  @media screen and (min-height: 1000px) {
    .contentStyle {
      min-height: 185vh;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1355px) {
  @media screen and (min-height: 800px) and (max-height: 1000px) {
    .contentStyle {
      min-height: 126vh;
    }
  }
  @media screen and (min-height: 1000px) {
    .contentStyle {
      min-height: 178vh;
    }
  }
}
@media screen and (min-width: 1366px) {
  @media screen and (min-height: 800px) and (max-height: 1000px) {
    .contentStyle {
      min-height: 80vh;
    }
  }
  @media screen and (min-height: 1000px) and (max-height: 1500px) {
    .contentStyle {
      min-height: 110vh;
    }
  }
  @media screen and (min-height: 1500px) {
    .contentStyle {
      min-height: 178vh;
    }
  }
}