.video-pdf-box{
    width: 100%;
    height: calc(100vh - 68px);
    display: flex;
    .react-pdf__Page__canvas{
        width: 100% !important;
        height: auto !important;
    }
}
.document-pdf{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    width: 100%;
    overflow-y: auto;
}
.page-pdf{
    width: 80%; 
    margin: 20px auto;
}