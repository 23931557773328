.login-content {
  .tabActive {
    background: #547ed6;
    height: 2px;
    width: 60px;
    margin-top: 12px;
  }
  .loginTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    .active {
      font-weight: 600;
    }
  }

  .con-div iframe {
    width: 461px;
    height: 290px;
    margin-top: -10px;
    /*margin-top:30px*/
  }
  .wx-con .gray {
    font-size: 12px;
    font-weight: 400;
    color: #afafb5;
    text-align: center;
    margin-bottom: 30px;
  }
  .wx-con .gray a {
    color: #3f85ed;
    cursor: pointer;
  }
  .content-box .cright .title {
    font-size: 20px;
    color: #2e2e2e;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 39px;
    line-height: 1;
  }

  .content-box .cright .tab {
    float: left;
    width: 50%;
    cursor: pointer;
  }

  .content-box .cright .tab .text {
    text-align: center;
    line-height: 1;
    color: #333333;
    font-size: 16px;
  }

  .content-box .cright .tab .line {
    width: 80px;
    height: 2px;
    margin-left: 30px;
    background: #ffffff;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .content-box .cright #tab-click .tabfon > .line {
    width: 114px !important;
    margin-left: -57px !important;
  }
  .content-box .cright .tab .l-line {
    width: 110px;
    margin-left: 60px;
  }

  .content-box .cright .active .line {
    background: #4085ed;

    height: 6px;
    opacity: 0.4;
    border-radius: 3px;
  }

  .content-box .cright .tel {
    width: 400px;
    position: relative;
  }

  .content-box .cright .ts {
    margin-left: 30px;
    color: #ff5151;
    font-size: 12px;
    position: absolute;
    left: -30px;
    bottom: -16px;
    line-height: 1;
  }

  .content-box .cright .left input {
    width: 90%;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 2px;
  }

  .content-box .cright input::placeholder {
    font-size: 14px;
    color: #afb0b5;
  }

  .content-box .cright input.showPHolder {
    font-size: 14px;
    color: #afb0b5;
  }

  .content-box .cright .tel .iconfont {
    position: absolute;
    width: 16px;
    height: 20px;
    top: 15px;
    left: 13px;
  }

  .content-box .cright .img-code {
    width: 400px;
    margin-left: 30px;
    display: none;
  }

  .content-box .cright .show {
    display: block;
  }

  .content-box .cright .img-code .left {
    position: relative;
    float: left;
    width: 280px;
    height: 50px;
  }

  .content-box .cright .img-code .left input {
    width: 100%;
    height: 100%;
    padding-left: 41px;
    border: 1px solid #ddd;
  }

  .content-box .cright .img-code .left .iconfont {
    position: absolute;
    width: 18px;
    height: 16px;
    top: 17px;
    left: 12px;
  }

  .content-box .cright .img-code .right {
    width: 110px;
    height: 50px;
    float: right;
    cursor: pointer;
  }

  .content-box .cright .phone-code {
    width: 400px;
    margin-left: 30px;
    margin-top: 30px;
  }

  .content-box .cright .phone-code .left {
    position: relative;
    float: left;
    width: 280px;
    height: 50px;
  }

  .content-box .cright .phone-code .left .iconfont {
    position: absolute;
    width: 18px;
    height: 16px;
    top: 17px;
    left: 12px;
  }

  .content-box .cright .phone-code .right {
    width: 110px;
    height: 50px;
    float: right;
    text-align: center;
    line-height: 50px;
    border-radius: 2px;
    color: #3f85ed;
    background: #fff;
    border: 1px solid #3f85ed;
    font-size: 14px;
    cursor: pointer;
  }

  .content-box .cright .phone-code .has-get {
    background: #f1f3f7;
    border: 1px solid #ddd;
    color: #afafb5;
  }

  .content-box .cright .password {
  }

  .content-box .cright .password .left {
    position: absolute;
    width: 18px;
    height: 20px;
    top: 15px;
    left: 12px;
  }

  .content-box .cright .password .right {
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
  }

  .content-box .fn {
    line-height: 1;
    height: 14px;
  }

  .content-box .fn input {
    display: none;
  }

  .content-box .fn input[type="checkbox"] + label::before {
    content: "\a0";
    display: inline-block;
    vertical-align: text-bottom;
    width: 12px;
    height: 14px;
    padding-left: 2px;
    margin-right: 7px;
    border-radius: 2px;
    background: #fff;
    line-height: 1;
    border: 1px solid #ddd;
    cursor: pointer;
  }

  .login-content
    .content-box
    .fn
    input[type="checkbox"]:checked
    + label::before {
    content: "\2713";
    color: #5ba4f8;
  }

  .content-box .fn label {
    font-size: 14px;
    color: #ccc;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1;
    margin-left: 30px;
  }

  .content-box .fn .find-paw {
    float: right;
    color: #afafb5;
    font-size: 14px;
    margin-right: 30px;
    cursor: pointer;
  }

  .content-box .go-login {
    width: 348px;
    height: 44px;
    background: #3f85ed;
    border-radius: 2px;
    text-align: center;
    line-height: 44px;
    color: #ffffff;
    cursor: pointer;
    font-size: 18px;
  }

  .content-box .go-login2 {
    margin-top: 20px;
  }

  .content-box .upd-login {
    margin-top: 20px;
  }
  .tshi {
    font-size: 12px;
    color: #afb0b5;
    margin-top: 24px;
  }
  .tshi i {
    color: #fc2b44;
    padding-right: 5px;
  }
  .content-box .protocol {
    text-align: left;
    /* padding-left: 30px; */
    // text-align: center;
    color: #999999;
    font-size: 12px;
    margin-top: 12px;
  }
  .content-box .protocol .protocolName {
    color: #3f85ed;
  }

  .content-box .wx-login {
    text-align: center;
    color: #36b233;
    font-size: 16px;
    cursor: pointer;
    margin: 10px 0 31px 0;
    line-height: 1;
  }

  .content-box .wx-login .iconfont {
    margin-right: 4px;
    font-size: 18px;
    color: #36b233;
  }

  .content-box .iconfont {
    color: #afb0b5;
  }

  .my-placeholder {
    color: #afb0b5;
  }

  .content-box input:focus {
    border: 0;
  }

  .zdwx-username,
  .zdwx-password {
    width: 348px;
    height: 46px;
    position: relative;
    padding: 4px 11px;
    border-radius: 0;
    Input {
      border: 0;
      border-radius: 0;
    }
  }
  .text-captcha {
    width: 232px;
    height: 46px;
    position: relative;
    border-radius: initial;
    padding-top: 2px;
  }
  .captcha-wrap {
    width: 430px;
  }
  .verify-code-btn {
    width: 104px;
    height: 46px;
    margin-left: 12px;
    border: 1px solid #3c80ef;
    border-radius: initial;
    color: #3c80ef;
    font-size: 16px;
    display: inline-flex; 
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    padding-top: 2px;
  }
  .boxs {
    margin-top: 40px;
    .ant-form-item {
      margin-bottom: 32px;
    }
    .user-password {
      .auto-login {
        margin: 12px 0 20px 0;
      }
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
