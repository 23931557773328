.comp-content-coupon-2 {
  width: 1200px;
  margin: 0px auto;
  background-size: 100% 100%;
  .comp-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 24px;
    .comp-title {
      color: #333333;
      font-size: 24px;
      font-weight: 500;
    }
    .look-more {
      font-size: 14px;
      color: #4e5969;
      line-height: 34px;
      cursor: pointer;
    }
  }
  .scroll-main-coupon-2 {
    width: 1200px;
    // height: 120px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    .card-box-coupon-2 {
      width: 588px;
      height: 114px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // background: url("../images/coupon-bg-2.png") no-repeat;
      // background-size: 100% 100%;
      margin-bottom: 32px;
      .left-card-coupon-2 {
        width: 183px;
        .left-type-0-2 {
          display: flex;
          flex-direction: column;
          align-items: center;
          // padding-top: 19px;
          color: #ffffff;
          .coupon-price-box-2 {
            display: flex;
            .coupon-price-box-icon-2 {
              font-size: 16px;
              line-height: 16px;
              margin-top: 15px;
            }
            .coupon-price-box-num-2 {
              font-size: 32px;
              line-height: 32px;
            }
          }
          .type-0-text-2 {
            font-size: 12px;
            margin-top: 3px;
          }
        }
        .left-type-1-2 {
          display: flex;
          flex-direction: column;
          align-items: center;
          // padding-top: 19px;
          color: #ffffff;
          .coupon-discount-box-2 {
            display: flex;
            .coupon-discount-box-icon-2 {
              font-size: 16px;
              line-height: 16px;
              margin-top: 15px;
            }
            .coupon-discount-box-num-2 {
              font-size: 32px;
              line-height: 32px;
              margin-right: 4px;
            }
          }
          .type-1-text-2 {
            font-size: 12px;
            margin-top: 3px;
          }
        }
        .coupon-btn-2 {
          width: 88px;
          height: 28px;
          margin: 8px auto 0px;
          background: #fff;
          color: #f46748;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
          border-radius: 27px;
          cursor: pointer;
        }
      }
      .right-card-coupon-2 {
        width: 404px;
        height: 100%;
        // padding: 14px 0px 14px 60px;
        padding-top: 12px;
        padding-right: 28px;
        padding-left: 27px;
        color: #ffffff;
        position: relative;
        .coupon-name-2 {
          font-size: 16px;
          line-height: 22px;
          height: 44px;
          // width: 220px;
          font-weight: 500;
          overflow: hidden;
          white-space: normal;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .coupon-time-2 {
          font-size: 12px;
          color: #fff4e9;
          line-height: 17px;
          margin-top: 4px;
          width: 360px;
          overflow: hidden;
          white-space: normal;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .coupon-time-2-white {
          font-size: 12px;
          color: #ffffff;
          line-height: 17px;
          margin-top: 4px;
          width: 360px;
          overflow: hidden;
          white-space: normal;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .coupon-explain-box-2 {
          display: flex;
          margin-top: 8px;
          .coupon-explain-text-2 {
            color: #ffcf86;
            font-size: 12px;
            line-height: 12px;
          }
          .coupon-explain-text-2-white {
            color: #ffffff;
            font-size: 12px;
            line-height: 12px;
          }
          .coupon-explain-icon-2 {
            width: 10px;
            height: 10px;
            background: url("../images/coupon-explain-icon.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 2px;
            margin-left: 5px;
            cursor: pointer;
          }
          .coupon-explain-icon-2-white {
            width: 10px;
            height: 10px;
            background: url("../images/coupon-explain-icon-white.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 2px;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .bg-time-big {
      background: url("../images/coupon-time-big-pc.png") no-repeat;
      background-size: 100% 100%;
    }
    .bg-use-big {
      background: url("../images/coupon-use-big-pc.png") no-repeat;
      background-size: 100% 100%;
    }
    .bg-lose-big {
      background: url("../images/coupon-lose-big-pc.png") no-repeat;
      background-size: 100% 100%;
    }
    .bg-active-big {
      background: url("../images/coupon-bg-big-pc.png") no-repeat;
      background-size: 100% 100%;
    }
    .right-24 {
      margin-right: 24px;
    }
  }
}
