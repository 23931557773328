.page-content {
  width: 1200px;
  margin: 0px auto;
  .top-all {
    display: flex;
    width: 100%;
    align-items: center;
    // margin-bottom: 32px;
    // padding-top: 24px;
    // padding-bottom: 24px;
    .nav-title {
      font-family: "PingFang SC";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
    }
    .exam-list {
      margin-left: 60px;
      flex: 1;
      ul{
        margin-bottom: 0;
      }
    }
    .nav-item {
      display: inline-block;
      padding: 8px 24px;
      position: relative;
      font-size: 14px;
      line-height: 36px;
      cursor: pointer;
      color: #333333;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
    .nav-active {
      position: relative;
      color: #3c80ef;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px;
      border-radius: 25px;
      background: #3c80ef14;
      border: 1px solid #3c80ef;
    }
  }
  .scroll-main {
    width: 100%;
    // height: 120px;
    position: relative;
    overflow: hidden;
    .left-button {
      display: flex;
      width: 16px;
      height: 28px;
      padding: 6px 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 0px 4px 4px 0;
      background: #00000040;
      position: absolute;
      left: 0;
      bottom: 40%;
      z-index: 999;
      .icon-symbol_left {
        color: #ffffff;
      }
    }
    .right-button {
      display: flex;
      width: 16px;
      height: 28px;
      padding: 6px 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 4px 0 0 4px;
      background: #00000040;
      position: absolute;
      right: 0;
      bottom: 40%;
      z-index: 999;
      .icon-symbol_right {
        color: #ffffff;
      }
    }
    .scroll-list {
      display: flex;
      padding: 10px 0;
      .card-box {
        width: 458px;
        height: 120px;
        display: flex;
        justify-content: space-between;
        margin-right: 20px;
        border-radius: 8px;
        background: #f5f7fa;
        box-shadow: 0 4px 28px 0 #3c70d114;
        flex-shrink: 0;
        // margin: 20px 16px;
        &:hover {
          .right-card-name {
            color: #3c80ef;
          }
        }
        .left-img {
          width: 214px;
          height: 100%;
          border-radius: 6px;
        }
        .right-card {
          width: 243px;
          height: 100%;
          background: "pink";
          background-color: #ffffff;
          padding: 12px 0px 12px 16px;
          border-radius: 0 8px 8px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .good-name {
            // color: #333333;
            font-size: 14px;
            font-weight: 600;
            max-width: 256px;
          }
          .good-name:hover {
            color: #3c80ef;
          }
          .right-card-sellingPoint {
            margin-top: 4px;
            color: #9fa1a4;
            font-size: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .teacher-all {
            display: flex;
            justify-content: space-between;
            // height: 52px;
            align-items: flex-end;
            width: 100%;
          }
          .recommend-teacher-box{
            min-width: 32px;
            max-width: 132px;
            overflow: hidden;
            .teacher-one-recommed{
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 50px;
              margin: 0;
              .teacher-name-l-recommed{
                width: 40px;
                overflow: hidden;
                white-space: nowrap;
                text-align: center;
              }
            }
          }
          .price-recommed-box{
            .recommed-price-icon .recommed-price-text{
              font-size: 10px;
            }
            .recommed-price-data{
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
