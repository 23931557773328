.footer{
    width: 100%;
    min-height: 86px;
    // margin-bottom: 20px;
    
    .footer-content{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        color: #8a8a8a;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .footer-first{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .footer-second{
           
            display: flex;
            justify-content: center;
            align-items: center;
            .police-logo {
                width: 20px;
                height: 20px;
                margin-bottom: -2px;
            }
            a{
                color: #8a8a8a;
                margin-right: 10px;
            }
        }
    }
}