.info-box {
  width: 1200px;
  margin: 0 auto;
  overflow-y: auto;
  border-radius: 6px;
  background: #fff;
  padding: 40px 60px;
  line-height: 34px;
}
.info-title {
  color: #333228;
  font-family: "PingFang SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.info-subhead {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 20px;
}
.info-mess {
  display: flex;
  align-items: center;
  height: 24px;
  justify-content: space-between;
  margin-top: 20px;
}
.info-time {
  color: #c4c6cb;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.info-issuer {
  margin-left: 16px;
}
.info-reading {
  color: #c4c6cb;
  font-family: "SF Pro Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  // line-height: 16px;
}
.info-summary {
  background-color: #fafafa;
  padding: 16px;
  color: #5b648d;
  line-height: 24px;
  border-radius: 8px;
  width: 1080px;
  margin-top: 28px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  margin-bottom: 28px;
  display: flex;
}
.icon-a-studentID {
  margin-right: 4px;
}
.biaodian {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
.iframe-box {
  // display: flex;
  // justify-content: center;
  min-height: 600px;
  // width: 1200px;
  margin: 0 auto;
  overflow-y: auto;
  // border-radius: 6px;
  background: #fff;
  // padding: 40px 60px;
}
.inframcontent {
  border: medium none;

  background: #fff;
}
