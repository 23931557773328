.add-price-box {
  width: 1200px;
  background-color: #ffffff;
  border: 1px solid #e5e6eb;
  margin: 24px auto;
  .box-title-box {
    height: 57px;
    padding-left: 20px;
    font-size: 18px;
    border-bottom: 1px solid #e5e6eb;
    align-items: center;
    .title-content {
      font-size: 18px;
    }
    .title-sub {
      color: #9fa1a4;
      font-size: 14px;
      margin-left: 8px;
    }
  }
  .box-content-add {
    padding: 32px 20px 28px;
    display: flex;
    flex-wrap: wrap;
    // padding-bottom: 24px;
    .scroll-main-add {
      width: 100%;
      height: 148px;
      position: relative;
      overflow-x: hidden;
      padding: 10px 0;
      .left-button-add {
        display: flex;
        width: 16px;
        height: 28px;
        padding: 6px 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 0px 4px 4px 0;
        background: #00000040;
        position: absolute;
        left: 0;
        bottom: 50%;
        z-index: 999;
        .icon-symbol_left {
          color: #ffffff;
        }
      }
      .right-button-add {
        display: flex;
        width: 16px;
        height: 28px;
        padding: 6px 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 4px 0 0 4px;
        background: #00000040;
        position: absolute;
        right: 0;
        bottom: 50%;
        z-index: 999;
        .icon-symbol_right {
          color: #ffffff;
        }
      }
      .scroll-list {
        display: flex;
        .card-box {
          width: 458px;
          height: 120px;
          display: flex;
          justify-content: space-between;
          margin-right: 20px;
          border-radius: 8px;
          background: #fff;
          box-shadow: 0 4px 28px 0 #3c70d114;
          flex-shrink: 0;
          // margin: 20px 16px;
          &:hover {
            .right-card-name {
              color: #3c80ef;
            }
          }
          .left-img {
            width: 214px;
            height: 100%;
            border-radius: 6px;
          }
          .right-card-add {
            width: 243px;
            height: 100%;
            padding: 12px 0px 12px 12px;
            border-radius: 0 8px 8px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .good-name {
              // color: #333333;
              font-size: 14px;
              font-weight: 600;
              max-width: 256px;
            }
            .good-name:hover {
              color: #3c80ef;
            }
            .right-card-sellingPoint {
              margin-top: 4px;
              color: #9fa1a4;
              font-size: 12px;
            }
            .bottom-box-add {
              display: flex;
              justify-content: space-between;
              height: 34px;
              width: 100%;
              .price-left-add {
                .line-price-add {
                  color: #999eb5;
                  font-size: 12px;
                  font-weight: 500;
                  text-decoration: line-through;
                  // margin-top: 20px;
                }
                .price-add {
                  .price-add-icon {
                    color: #fe4e47;
                    font-size: 12px;
                    font-weight: 600;
                    margin-top: 5px;
                  }
                  .price-add-data {
                    color: #fe4e47;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 20px;
                  }
                }
              }
              .price-right-add {
                width: 24px;
                height: 24px;
                border-radius: 50% 50%;
                border: 1px solid #e5e6eb;
                margin-right: 16px;
                margin-top: 13px;
              }
              .price-right-add-select {
                background: url("../imgs/good-select.png") no-repeat;
                background-size: 100% 100%;
                margin-right: 16px;
                margin-top: 13px;
                width: 24px;
                height: 24px;
              }
            }
          }
        }
        .card-select {
          border: 1px solid #f46748;
        }
      }
    }
  }
}
.flex {
  display: flex;
}
