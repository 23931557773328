.sku-list-select {
  .price-box {
    width: 1200px;
    height: 98px;
    margin: 24px auto;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
  }
  .add-level-text-box-0 {
    display: flex;
    margin-left: 60px;
    .add-level-text {
      color: #fe4e47;
      font-size: 12px;
      font-weight: 500;
      margin-top: 2px;
      margin-top: 22.5px;
    }
    .add-level-text-num {
      color: #fe4e47;
      font-size: 12px;
      font-weight: 400;
      background: var(---Brand1-3, #fde8e6);
      height: 20px;
      line-height: 20px;
      border-radius: 4px;
      padding: 0 4px;
      margin-left: 4px;
      margin-top: 21px;
    }
  }
  .add-level-text-box-1 {
    color: #fe4e47;
    font-size: 12px;
    font-weight: 500;
    margin-top: 22.5px;
    margin-left: 60px;
  }
  .price-box-left-activty {
    margin-left: 60px;
    margin-top: 0px;
    display: flex;
  }
  .price-box-left {
    margin-left: 60px;
    margin-top: 30px;
    display: flex;
  }

  .price-icon {
    font-size: 16px;
  }

  .price-real {
    color: #fe4e47;
    font-size: 32px;
    font-weight: 600;
    margin-right: 12px;
  }

  .price-old {
    color: #9fa1a4;
    font-size: 14px;
    margin-top: 17px;
    text-decoration: line-through;
  }

  .price-box-right {
    margin-top: 24px;
    margin-right: 60px;
    display: flex;
  }

  .interest-box {
    color: #9fa1a4;
    font-size: 14px;
    margin-top: 24px;
    margin-right: 36px;
  }

  .add-list-btn {
    width: 150px;
    height: 50px;
    border-radius: 4px 0 0 4px;
    background: #ffffff;
    color: #fe4e47;
    font-size: 20px;
    text-align: center;
    line-height: 46px;
    cursor: pointer;
    border-radius: 4px 0 0 4px;
    border: 2px solid #fe4e47;
    &:hover {
      border: 2px solid #fe716b;
    }
  }
  .buy-btn {
    width: 150px;
    height: 50px;
    border-radius: 0 4px 4px 0;
    background: #fe4e47;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    &:hover {
      background-color: #fe716b;
    }
  }
  .buy-btn-level {
    width: 150px;
    height: 50px;
    border-radius: 4px;
    background: #fe4e47;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    &:hover {
      background-color: #fe716b;
    }
  }
  .list-box {
    width: 1200px;
    margin: 24px auto;
    background-color: #ffffff;
    border-radius: 8px;
  }
  .list-title {
    height: 54px;
    padding: 16px 20px;
    border-bottom: 1px solid #f2f3f5;
    font-size: 16px;
  }
  .list-content {
    margin: 12px 60px;
  }
  .list-card-one {
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .list-card-border {
    border-bottom: 1px solid #f6f6f6;
  }
  .list-card-img {
    width: 128px;
    height: 72px;
  }
  .list-card-name-box {
    margin-left: 20px;
    width: 467.5px;
  }
  .list-card-name {
    color: #333333;
    font-size: 14px;
    line-height: 20px;
    margin-top: 15.5px;
  }
  .list-card-sku-name {
    color: #9fa1a4;
    font-size: 12px;
    line-height: 17px;
    margin-top: 4px;
  }
  .list-card-price-box {
    display: flex;
    padding-top: 25px;
    width: 374.5px;
  }
  .list-card-price {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  .list-card-linePrice {
    margin-left: 4px;
    font-size: 14px;
    color: #4e5969;
    font-weight: 500;
    line-height: 22px;
    text-decoration: line-through;
  }
  .list-card-number-box {
    margin-top: 24px;
    display: flex;
    height: 24px;
    line-height: 24px;
  }
  .list-card-reduce {
    height: 24px;
    width: 24px;
    border-radius: 100px;
    background-color: #f2f3f5;
    line-height: 19px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
  }
  .list-card-number {
    font-size: 16px;
    font-weight: 500;
    width: 42px;
    text-align: center;
    line-height: 24px;
  }
  .list-card-add {
    height: 24px;
    width: 24px;
    border-radius: 100px;
    background-color: #f2f3f5;
    line-height: 19px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
  }
  .list-card-add-dis {
    height: 24px;
    width: 24px;
    border-radius: 100px;
    background-color: #f2f3f5;
    color: #c9cdd4;
    line-height: 19px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
  }

  // 按钮
  .price-box-select {
    width: 1200px;
    height: 98px;
    margin: 24px auto;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0 2px 20px #797b7e1a;
  }
  .price-box-left-select {
    margin-left: 60px;
    margin-top: 30px;
    display: flex;
  }
  .price-name-select {
    color: #9fa1a4;
    font-size: 14px;
    padding-top: 18px;
    margin-right: 12px;
  }
  .price-icon-select {
    font-size: 16px;
  }

  .price-real-select {
    color: #fe4e47;
    font-size: 32px;
    font-weight: 600;
    margin-right: 12px;
  }

  .price-old-select {
    color: #9fa1a4;
    font-size: 14px;
    margin-top: 17px;
    text-decoration: line-through;
  }
  .number-select-text {
    color: #9fa1a4;
    font-size: 14px;
    padding-top: 18px;
    margin-left: 40px;
  }
  .number-select-val {
    color: #fe4e47;
    margin-left: 5px;
    margin-right: 5px;
  }
}
