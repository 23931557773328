.page-content {
  // background: #fff;
  min-width: 1200px;
}

.content-wrap .setmeal-groups {
  // background: #f6f7f9;
  background: #ffffff;
  padding-bottom: 30px;
}
