.order-pay-gift {
  width: 1200px;
  background-color: #ffffff;
  border: 1px dashed #ff7d00;
  padding: 16px 20px;
  .pay-gift-title {
    width: 392px;
    height: 22px;
    margin: 0 auto;
    // background-color: red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .gift-title-left {
      width: 66px;
      height: 8px;
    }
    .gift-title-text {
      color: #ff7d00;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }
    .gift-title-right {
      width: 66px;
      height: 8px;
    }
  }
  .pay-gift-content {
    margin-top: 16px;
    .pay-gift-header {
      height: 44px;
      background: #f5f5f5;
      line-height: 44px;
      text-align: center;
    }
    .order-info-item {
      border: 1px solid #f2f3f5;
    }
    .order-info-border-right {
      border-right: 1px solid #f2f3f5;
    }
    .order-info-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
    .order-info-commodity {
      display: flex;
    }
    .order-info-img {
      width: 128px;
      height: 72px;
      margin-top: 12px;
      margin-left: 12px;
      margin-bottom: 12px;
    }
    .order-commodity-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 16px;
    }
    .commodity-name {
      color: #333333;
      font-size: 14px;
      max-width: 280px;
    }
    .commodity-skuName {
      font-size: 12px;
      color: #9fa1a4;
      line-height: 12px;
      margin-top: 4px;
    }
    .gift-exchange-all {
      margin-top: 24px;
      .gift-exchange-all-title {
        color: #333333;
        font-size: 18px;
      }
      .gift-exchange-all-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;
        gap: 12px;
      }
    }
    .gift-exchange-only {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .gift-exchange-only-title {
        color: #333333;
        font-size: 18px;
      }
      .gift-exchange-only-content {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
    .gift-exchange-one-common {
      width: 448px;
      height: 74px;
      border-radius: 8px;
      background: #f7f7f7;
      display: flex;
      justify-content: space-between;
      padding: 12px 40px;
      .gift-exchange-one-common-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .gift-exchange-one-common-val {
          color: #ff7d00;
          font-size: 20px;
          font-weight: 500;
          -webkit-user-select: text;
          -moz-user-select: text;
          -ms-user-select: text;
          user-select: text;
        }
        .gift-exchange-one-common-tip {
          color: #9fa1a4;
          font-size: 14px;
        }
      }
      .gift-exchange-one-common-right {
        width: 66px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #ff7d00;
        color: #ffffff;
        font-size: 14px;
        cursor: pointer;
        border-radius: 36px;
        margin-top: 9px;
      }
    }
    .gift-coupon-all {
      margin-top: 24px;
      .gift-coupon-all-title {
        color: #333333;
        font-size: 18px;
      }
      .gift-coupon-all-content {
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
      }
    }
    .gift-coupon-only {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .gift-coupon-only-title {
        color: #333333;
        font-size: 18px;
        display: flex;
        justify-content: center;
      }
      .gift-coupon-only-content {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
    .gift-coupon-one-common {
      width: 448px;
      height: 114px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: url("../images/coupon-bg-small-pc.png") no-repeat;
      background-size: 100% 100%;
      .left-card-coupon {
        width: 137px;
        .left-type-0 {
          display: flex;
          flex-direction: column;
          align-items: center;
          // padding-top: 19px;
          // padding-top: 12px;
          color: #ffffff;
          .coupon-price-box {
            display: flex;
            .coupon-price-box-icon {
              font-size: 16px;
              line-height: 16px;
              margin-top: 14px;
            }
            .coupon-price-box-num {
              font-size: 32px;
              line-height: 32px;
            }
          }
          .type-0-text {
            font-size: 12px;
            margin-top: 2px;
          }
        }
        .left-type-1 {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #ffffff;
          .coupon-discount-box {
            display: flex;
            .coupon-discount-box-icon {
              font-size: 16px;
              line-height: 16px;
              margin-top: 14px;
            }
            .coupon-discount-box-num {
              font-size: 32px;
              line-height: 32px;
              margin-right: 4px;
            }
          }
          .type-1-text {
            font-size: 12px;
            margin-top: 2px;
          }
        }
        .coupon-btn {
          width: 88px;
          height: 28px;
          background: #fff;
          color: #f46748;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
          border-radius: 27px;
          cursor: pointer;
          margin: 8px auto 0px;
        }
      }
      .right-card-coupon {
        width: 311px;
        height: 100%;
        padding-top: 12px;
        padding-left: 16px;
        padding-right: 16px;
        color: #ffffff;
        position: relative;
        .coupon-name {
          font-size: 16px;
          height: 44px;
          line-height: 22px;
          font-weight: 500;
          overflow: hidden;
          white-space: normal;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .coupon-time {
          font-size: 12px;
          color: #fff4e9;
          line-height: 17px;
          margin-top: 4px;
          // width: 159px;
          overflow: hidden;
          white-space: normal;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .coupon-time-white {
          font-size: 12px;
          color: #ffffff;
          line-height: 17px;
          margin-top: 4px;
          // width: 159px;
          overflow: hidden;
          white-space: normal;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .coupon-explain-box {
          display: flex;
          margin-top: 8px;
          .coupon-explain-text {
            color: #ffcf86;
            font-size: 12px;
            line-height: 12px;
          }
          .coupon-explain-icon {
            width: 10px;
            height: 10px;
            background: url("../images/coupon-explain-icon.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 2px;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .express-box {
    display: flex;
    justify-content: center;
    margin: 8px auto 0px;
    color: #4e5969;
    font-size: 14px;
    cursor: pointer;
    .express-btn-icon {
      height: 12px;
      width: 12px;
      margin-left: 5px;
      margin-top: 5px;
    }
    .retract-btn-icon {
      height: 12px;
      width: 12px;
      margin-left: 5px;
      margin-top: 3px;
    }
  }
}
