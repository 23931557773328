.modal-activity-bg {
  height: 400px;
  // background-image: url("../images/add-level-bg.png");
  background-size: 590px 400px;
  position: relative;
  background-repeat: no-repeat;
  .ant-modal-content {
    background-color: inherit;
    box-shadow: none;
    position: static;
    // padding-bottom: 50px;
    .ant-modal-header {
      background-color: inherit;
    }
  }
  .countdown-box {
    color: #ffffff;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    // margin-top: 211px;
    // margin-left: 159px;
    position: absolute;
    top: 211px;
    left: 159px;
    width: 272px;
    height: 36px;
    // background-color: red;
    justify-content: center;
    align-items: center;
    .activity-day {
      margin-left: 12px;
      margin-right: 4px;
    }
    .activity-hour {
      margin-left: 12px;
      margin-right: 5px;
    }
    .activity-min {
      margin-left: 5px;
      margin-right: 5px;
    }
    .activity-second {
      margin-left: 5px;
      //   margin-right: 4px;
    }
    .time-num-box {
      width: 20px;
      height: 18px;
      flex-shrink: 0;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.2);
      text-align: center;
      line-height: 18px;
      //   margin-top: 3px;
      font-weight: 400;
    }
  }
  .attend-btn {
    width: 400px;
    height: 56px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    line-height: 56px;
    font-size: 18px;
    border-radius: 28px;
    font-weight: 600;
    background: linear-gradient(270deg, #fe2f23 0%, #ff8274 100%);
    position: absolute;
    left: 95px;
    bottom: 30px;
  }
}
.modal-activity-level {
  background-image: url("../images/add-level-bg.png");
}
.modal-activity-replace {
  background-image: url("../images/replace-bug-bg.png");
}
