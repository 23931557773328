.qrcode-pop-wrap {
  .ant-modal-content {
    padding: 0;
  }
  .qrcode-content-wrap {
    position: relative;
    .qr-image {
      position: absolute;
      right: 118px;
      bottom: 93px;
    }
  }
}
.qrcode-pop-wrap-radius {
  .ant-modal-content {
    padding: 0;
    border-radius: 12px;
  }
  .qrcode-content-wrap {
    position: relative;
    .qr-image {
      position: absolute;
      right: 118px;
      bottom: 93px;
    }
  }
}