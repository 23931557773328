.iframebox {
  background: #fff;
  width: 1200px;
  margin: 60px auto;
  min-height: 600px;
  padding: 40px 60px;
}
.infram-content {
  background-color: #fff;
  min-height: 600px;
  border: none;
}
