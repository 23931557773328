// PreviewAgreement.scss



// 协议框的样式
.agreement-box {
  width: 1200px;
  background: #fff; // 背景颜色
  padding: 24px; // 内边距
  min-height: 280px; // 最小高度
  // border: 1px solid #d9d9d9; // 边框
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); // 阴影效果
  border-radius: 4px; // 边框圆角
  margin: 0 auto; // 居中
}
.pdf-content{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.video-pdf-box{
    .react-pdf__Page__canvas{
      width: 100%!important;
    }
}

// 签署按钮的样式
.sign-button {
  color: #fff;
  display: flex;
  width: 380px;
  height: 52px;
  padding: 10px 12px;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  background-color: #E51600;
  cursor: pointer;
  outline: none;
  border: none;
}
