.background-container {
  width: 1200px;
  height: 100vh;
  background: url('https://resource.wangxiao.net/pc/images/signAgreement.png') no-repeat center center;
  margin: 0 auto;
}
.success-container{
  margin-top: 150px;
  width: 400px;
  height: 600px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-succeed{
  color: #E51600;
  font-size: 64px;
}
.success-text {
  color: #e51600;
  font-family: "PingFang SC";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
}
.congrats {
  margin-top: 4px;
  color: #9fa1a4;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}

.study-btn {
  margin-top: 38px;
  width: 295px;
  height: 48px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 36px;
  color: #fff;
  background-color: #e51600;
  border: none;
  cursor: pointer;
}