.coupon-my-content {
  min-height: 550px;
  width: 996px;
  .coupon-tab-list {
    width: 100%;
    height: 54px;
    line-height: 54px;
    padding: 2px 42px;
    border-bottom: 1px solid #F2F3F5;
    
  }
  .agreement-content {
    padding: 32px 40px;
    width: 1100px;
  }
  .coupon-my-list {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 33px;
    margin: 0 auto;
    min-height: 500px;
    align-content: flex-start;
    .coupon-item-person {
      .card-box-coupon {
        width: 448px;
        height: 114px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-right: 30px;
        margin-bottom: 20px;
        .left-card-coupon {
          width: 137px;
          .left-type-0 {
            display: flex;
            flex-direction: column;
            align-items: center;
            // padding-top: 19px;
            // padding-top: 12px;
            color: #ffffff;
            .coupon-price-box {
              display: flex;
              .coupon-price-box-icon {
                font-size: 16px;
                line-height: 16px;
                margin-top: 14px;
              }
              .coupon-price-box-num {
                font-size: 32px;
                line-height: 32px;
              }
            }
            .type-0-text {
              font-size: 12px;
              margin-top: 2px;
            }
          }
          .left-type-1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            // padding-top: 19px;
            // padding-top: 10px;
            color: #ffffff;
            .coupon-discount-box {
              display: flex;
              .coupon-discount-box-icon {
                font-size: 16px;
                line-height: 16px;
                margin-top: 14px;
              }
              .coupon-discount-box-num {
                font-size: 32px;
                line-height: 32px;
                margin-right: 4px;
              }
            }
            .type-1-text {
              font-size: 12px;
              margin-top: 2px;
            }
          }
          .coupon-btn {
            width: 88px;
            height: 28px;
            background: #fff;
            color: #f46748;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            border-radius: 27px;
            cursor: pointer;
            margin: 8px auto 0px;
          }
        }
        .right-card-coupon {
          width: 311px;
          height: 100%;
          // padding: 14px 0px 14px 32px;
          padding-top: 12px;
          padding-left: 16px;
          padding-right: 16px;
          color: #ffffff;
          position: relative;
          .coupon-name {
            font-size: 16px;
            height: 44px;
            line-height: 22px;
            font-weight: 500;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .coupon-time {
            font-size: 12px;
            color: #fff4e9;
            line-height: 17px;
            margin-top: 4px;
            width: 159px;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .coupon-time-white {
            font-size: 12px;
            color: #ffffff;
            line-height: 17px;
            margin-top: 4px;
            width: 159px;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .coupon-explain-box {
            display: flex;
            margin-top: 8px;
            .coupon-explain-text {
              color: #ffcf86;
              font-size: 12px;
              line-height: 12px;
            }
            .coupon-explain-text-white {
              color: #ffffff;
            }
            .coupon-explain-icon {
              width: 10px;
              height: 10px;
              background: url("../images/coupon-explain-icon.png") no-repeat;
              background-size: 100% 100%;
              margin-top: 2px;
              margin-left: 5px;
              cursor: pointer;
            }
            .coupon-explain-icon-white {
              width: 10px;
              height: 10px;
              background: url("../images/coupon-explain-icon-white.png")
                no-repeat;
              background-size: 100% 100%;
              margin-top: 2px;
              margin-left: 5px;
              cursor: pointer;
            }
          }
        }
      }
      .bg-time-small {
        background: url("../images/coupon-time-small-pc.png") no-repeat;
        background-size: 100% 100%;
      }
      .bg-use-small {
        background: url("../images/coupon-use-small-pc.png") no-repeat;
        background-size: 100% 100%;
      }
      .bg-lose-small {
        background: url("../images/coupon-lose-small-pc.png") no-repeat;
        background-size: 100% 100%;
      }
      .bg-active-small {
        background: url("../images/coupon-bg-small-pc.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .empty-box-coupon-my {
      margin: 100px auto 0px;
    }
    .left-index {
      margin-right: 32px;
    }
  }
}
