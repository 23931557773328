.personal-cneter-outwrap {
  width: 1200px;
  margin: 0 auto;
  
  .layout {
    display: flex;
    
  }

  /* 左侧导航栏 */
  .l-container {
    // width: 180px;
    height: 304px;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    border: 1px solid #E5E6EB;
  }

  .item {
    align-items: center;
    padding: 14px 28px;
    // padding: 14px 88px 14px 28px;
    background-color: #fff;
    flex-shrink: 0;
    cursor: pointer;
    width: 180px;
  }
  .item-name {
    color: #333333;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
  }

  .active-item {
    background-color: #f7f8fa;
    border-left: 3px solid #1890ff;
    color: #3c80ef;
  }

  /* 个人中心盒子 */
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
    width: 1200px;
    height: 80px;
    padding-left: 20px;
    flex-shrink: 0;
  }

  /* 个人中心 */
  .personalcenter {
    font-size: 24px;
    text-align: left;
    color: #4e5969;
    font-family: "PingFang SC";
    font-weight: 400;
    font-style: normal;
    line-height: 33.6px;
  }

  /* 右侧内容 */
  .r-content {
    margin-left: 20px;
    flex-grow: 1;
    background-color: #f5f6f8;
  }

  .active-name {
    margin: 8px 0 20px 39px;
  }

  .active-component {
    background-color: #fff;
    border:1px solid var(---fill-3, #E5E6EB);
  }
}
