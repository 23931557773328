.pay-type-common-modal {
  background-image: url("https://resource.wangxiao.net/pc/images/pay_modal_bg.png");
  background-size: 680px 520px;
  background-repeat: no-repeat;
  .ant-modal-content {
    background-color: inherit;
    padding-bottom: 50px;
    .ant-modal-header {
      background-color: inherit;
    }
  }
}
.pay-order-component-space {
  height: 30px;
}
.pay-order-component-wrap {
  padding-bottom: 50px;
  .pay-type {
    display: flex;
    justify-content: space-around;
    .wechat-pay {
      position: relative;
      display: flex;
      cursor: pointer;
      width: 180px;
      padding: 12px 40px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #dddddd;
      .space {
        width: 10px;
      }
      .exclude {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 23.97px;
        height: 22.1px;
      }
      .wechat-img {
        width: 23.97px;
        height: 22.1px;
      }
    }
    .aplipay-pay {
      position: relative;
      display: flex;
      cursor: pointer;
      width: 180px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #dddddd;
      .exclude {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 23.97px;
        height: 22.1px;
      }
      .space {
        width: 10px;
      }
      img {
        width: 21.71px;
        height: 21.71px;
      }
    }
    .active {
      border: 1px solid #f46748;
    }
  }
  .pay-tip {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .pay-amount {
    display: flex;
    justify-content: center;
    font-size: 32px;
  }
  .alipay-tip {
    display: flex;
    justify-content: center;
    .aplipay-text-tip {
      align-self: center;
      width: 200px;
      color: #4e5969;
      font-size: 22px;
      font-weight: 500;
    }
    img {
      width: 140px;
      height: 140px;
    }
  }
  .pay-qrcode {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    .pay-qrcode-spin {
      width: 140px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .pay-scan {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }
}
