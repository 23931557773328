.list-content {
  width: 100%;
  margin: 0px auto;
  .top-title {
    // color: #333333;
    font-family: "PingFang SC";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    padding-top: 24px;
    padding-bottom: 24px;
    // margin-bottom: 32px;
  }
  .topnav {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 28px;
    .nav-item {
      display: inline-block;
      padding: 8px 24px;
      position: relative;
      font-size: 14px;
      line-height: 36px;
      cursor: pointer;
      color: #333333;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
    .nav-active {
      position: relative;
      color: #3c80ef;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px;
      border-radius: 25px;
      background: #3c80ef14;
      border: 1px solid #3c80ef;
    }
  }
  .flexend {
    justify-content: flex-end;
  }
  .flexstart {
    justify-content: space-between;
  }
  .main-content {
    width: 100%;
    .goods-content {
      width: 100%;
      height: 153px;
      display: flex;
      justify-content: space-between;
      background-color: rgb(252, 252, 253);
      // margin-top: 32px;

      .goods-img {
        width: 272px;
        height: 100%;
        border-radius: 8px;
        position: relative;
        .goods-thumbnail {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
        .sellingnumber {
          position: absolute;
          bottom: 0;
          right: 0;
          border-radius: 12px 0 10px 12px;
          background: #ff6d1b;
          padding: 2px 16px;
          color: #ffffff;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
        }
      }
      .sellpoint-box {
        padding: 24px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 16px;
        flex: 1;

        .goods-name {
          // color: #333333;
          font-family: "PingFang SC";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }
        .goods-name:hover {
          color: #3c80ef;
        }
        .goods-sellpoint {
          color: #9fa1a4;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }
      .teacher-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        padding: 24px 40px 24px 0;
        .item-teacherList {
          display: flex;
          flex-direction: row;
          align-items: center;
          .teacher-item {
            width: auto;
            text-align: center;
            // margin-bottom: 28px;
            margin-left: 10px;

            img {
              width: 30px;
              height: 30px;
              border-radius: 40px;
              margin-bottom: 8px;
            }
            .teacher-name{
              font-size: 10px;
              color: #9FA1A4;
            }
          }
        }
        .item-pricebox {
          display: flex;
          align-items: baseline;

          .item-lineprice {
            color: #999eb5;
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            margin-top: 9px;
            text-decoration: line-through;
            margin-right: 8px;
            margin-bottom: 0;
          }
          .price-icon {
            color: #fe4e47;
            text-align: right;
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            margin-right: 1px;
          }
          .item-realPrice {
            color: #fe4e47;
            text-align: right;
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0;
            .price-number {
              color: #fe4e47;
              text-align: right;
              font-family: "PingFang SC";
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
