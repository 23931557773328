.banner-box {
  width: 1200px;
  height: 120px;
  position: relative;
  cursor: pointer;
  .activity-banner-box {
    width: 1200px;
    height: 120px;
    cursor: pointer;
  }
  .banner-content-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .banner-content-title {
      display: flex;
      justify-content: center;
      color: #ffffff;
      font-size: 28px;
      line-height: 28px;
      font-weight: 500;
      margin-top: 3px;
      .banner-content-title-num {
        font-size: 32px;
        color: #ffd600;
        font-weight: 600;
        margin-left: 8px;
        margin-right: 8px;
      }
    }
    .banner-content-title-replace {
      display: flex;
      justify-content: center;
      color: #50362c;
      font-size: 28px;
      line-height: 28px;
      font-weight: 500;
      margin-top: 3px;
      .banner-content-title-num {
        font-size: 32px;
        color: #ff7d00;
        font-weight: 600;
        margin-left: 8px;
        margin-right: 8px;
      }
    }
    .banner-content-time {
      display: flex;
      color: #ffffff;
      font-size: 20px;
      margin-top: 11px;
      font-weight: 500;
      .banner-time-box {
        width: 36px;
        height: 32px;
        text-align: center;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.2);
      }
      .banner-time-day {
        margin-left: 24px;
        margin-right: 8px;
      }
      .banner-time-hour {
        margin-left: 24px;
        margin-right: 10px;
      }
      .banner-time-min {
        margin-left: 10px;
        margin-right: 10px;
      }
      .banner-time-second {
        margin-left: 10px;
      }
    }
    .banner-content-time-replace {
      display: flex;
      color: #50362b;
      font-size: 20px;
      margin-top: 11px;
      font-weight: 500;
      .banner-time-box {
        width: 36px;
        height: 32px;
        text-align: center;
        border-radius: 8px;
        background: rgba(80, 54, 43, 0.1);
      }
      .banner-time-day {
        margin-left: 24px;
        margin-right: 8px;
      }
      .banner-time-hour {
        margin-left: 24px;
        margin-right: 10px;
      }
      .banner-time-min {
        margin-left: 10px;
        margin-right: 10px;
      }
      .banner-time-second {
        margin-left: 10px;
      }
    }
  }
}
