.goodsshow-output-container {
  width: 1200px;
  margin: 0 auto;
  .goodsshow-header-container {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 24px;
    .goodsshow-header-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
    }
    .goodsshow-header-showMore {
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.6px;
      height: 19.6px;
      cursor: pointer;
    }
    .look-more {
      font-size: 14px;
      color: #4e5969;
      line-height: 34px;
      cursor: pointer;
    }
  }
  .recursion-tree-box {
    border-radius: 12px;
    box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.12);
    padding: 0 0 32px 0;
    .treeBox{
      margin: 0 32px;
    }
  }
}

.goodsshow-currentStyles-group4 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 0;
  padding-right: 18;
  padding-bottom: 0;
  padding-left: 18;
  .goodsshow-currentStyles-font1 {
    cursor: pointer;
  }
  .goodsshow-active-style {
    font-size: 14;
    color: #e51600;
    font-weight: 600;
  }
}
.xy-modal {
  width: 680px;
  height: 680px;
  text-align: center;
  .modal-title {
    width: 100%;
    height: 57px;
    line-height: 57px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #e5e6eb;
  }
  .ant-modal-content {
    padding: 0;
  }
  .video-pdf-box {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .modal-footer {
    display: flex;
    justify-content: center;
    padding-bottom: 36px;
    .sure-button {
      width: 380px;
      height: 52px;
      line-height: 52px;
      color: #ffffff;
      background: #e51600;
      text-align: center;
      border-radius: 36px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }
  }

}
.custom-tabs-container {
  display: flex;
  justify-content: center; /* 水平居中整个任务栏 */
  width: 100%;
  .ant-tabs-top{
    width: 100%;
    .ant-tabs-nav {
      .ant-tabs-nav-wrap{
        display: flex;
        justify-content: center;
      }
    }
  }
  
}

.custom-tabs .ant-tabs-nav {
  display: flex;
  justify-content: space-between; /* 使每个 tab 平均分配空间 */
  padding: 0 100px; /* 确保边框延伸到外框边际 */
}

.custom-tabs .ant-tabs-tab {
  flex: 1; /* 使每个 tab 占据相等的空间 */
  text-align: center; /* 内容居中 */
  // padding: 10px 15px;
}
.ant-tabs-nav .ant-tabs-tab {
  padding: 15px;
  
}
.ant-tabs-tab-btn{
  color:#333333;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
}
.treeBoxStyle{
  padding:0  32px;
}

