.activityList-box-orderSettlement {
  width: 1200px;
  border: 1px solid var(---fill-3, #e5e6eb);
  background: #fff;
  margin: 24px auto;
  .activityList-box-title {
    height: 57px;
    padding-left: 20px;
    font-size: 18px;
    border-bottom: 1px solid #e5e6eb;
    line-height: 57px;
  }
  .activityList-box-content {
    padding: 23px 20px;
    flex-wrap: wrap;
    gap: 20px;
    .activityList-one {
      height: 60px;
      width: 340px;
      border-radius: 8px;
      background: var(--, #fff);
      display: flex;
      position: relative;
      .activityList-one-left {
        width: 40px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 4px;
        background: var(---Brand1-3, #fde8e6);
        color: #fe4e47;
        font-size: 12px;
        margin-top: 10px;
        margin-left: 12px;
      }
      .activityList-one-right {
        margin-left: 4px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        // justify-content: center;
        .activityList-one-name {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-top: 1px;
        }
        .activityList-one-reason {
          color: #989ead;
          font-size: 12px;
          line-height: 16px;
          margin-top: 2px;
        }
      }
      .check-coupn-icon {
        position: absolute;
        right: -1px;
        bottom: 0px;
        width: 28px;
        height: 28px;
        font-size: 28px;
        color: #f46748;
      }
    }
    .activityList-one-active {
      border: 1px solid #f46748;
    }
    .activityList-one-on-active {
      border: 1px solid var(---fill-2, #f2f3f5);
    }
  }
}
