.comp-content {
  width: 1200px;
  margin: 0px auto;
  background-size: 100% 100%;
  .comp-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    // background-color: red;
    // margin-bottom: 32px
    padding-top: 24px;
    padding-bottom: 24px;
    .comp-title {
      color: #333333;
      font-size: 24px;
      font-weight: 500;
    }
    .look-more {
      font-size: 14px;
      color: #4e5969;
      line-height: 34px;
      cursor: pointer;
    }
  }
  .scroll-main {
    width: 1200px;
    // height: 120px;
    position: relative;
    overflow: hidden;
    .left-button {
      display: flex;
      width: 16px;
      height: 28px;
      padding: 6px 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 0px 4px 4px 0px;
      background: #00000040;
      position: absolute;
      left: 0;
      bottom: 40%;
      z-index: 999;
      .icon-symbol_left {
        color: #ffffff;
      }
    }
    .right-button {
      display: flex;
      width: 16px;
      height: 28px;
      padding: 6px 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 4px 0 0 4px;
      background: #00000040;
      position: absolute;
      right: 0;
      bottom: 40%;
      z-index: 999;
      .icon-symbol_right {
        color: #ffffff;
      }
    }
    .scroll-list {
      display: flex;
      .card-box-coupon {
        width: 448px;
        height: 114px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 24px;
        .left-card-coupon {
          width: 137px;
          .left-type-0 {
            display: flex;
            flex-direction: column;
            align-items: center;
            // padding-top: 19px;
            // padding-top: 12px;
            color: #ffffff;
            .coupon-price-box {
              display: flex;
              .coupon-price-box-icon {
                font-size: 16px;
                line-height: 16px;
                margin-top: 14px;
              }
              .coupon-price-box-num {
                font-size: 32px;
                line-height: 32px;
              }
            }
            .type-0-text {
              font-size: 12px;
              margin-top: 2px;
            }
          }
          .left-type-1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            // padding-top: 19px;
            // padding-top: 10px;
            color: #ffffff;
            .coupon-discount-box {
              display: flex;
              .coupon-discount-box-icon {
                font-size: 16px;
                line-height: 16px;
                margin-top: 14px;
              }
              .coupon-discount-box-num {
                font-size: 32px;
                line-height: 32px;
                margin-right: 4px;
              }
            }
            .type-1-text {
              font-size: 12px;
              margin-top: 2px;
            }
          }
          .coupon-btn {
            width: 88px;
            height: 28px;
            background: #fff;
            color: #f46748;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            border-radius: 27px;
            cursor: pointer;
            margin: 8px auto 0px;
          }
        }
        .right-card-coupon {
          width: 311px;
          height: 100%;
          // padding: 14px 0px 14px 32px;
          padding-top: 12px;
          padding-left: 16px;
          padding-right: 16px;
          color: #ffffff;
          position: relative;
          .coupon-name {
            font-size: 16px;
            height: 44px;
            line-height: 22px;
            font-weight: 500;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .coupon-time {
            font-size: 12px;
            color: #fff4e9;
            line-height: 17px;
            margin-top: 4px;
            width: 280px;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .coupon-time-white {
            font-size: 12px;
            color: #ffffff;
            line-height: 17px;
            margin-top: 4px;
            width: 280px;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .coupon-explain-box {
            display: flex;
            margin-top: 8px;
            .coupon-explain-text {
              color: #ffcf86;
              font-size: 12px;
              line-height: 12px;
            }
            .coupon-explain-text-white {
              color: #ffffff;
            }
            .coupon-explain-icon {
              width: 10px;
              height: 10px;
              background: url("../images/coupon-explain-icon.png") no-repeat;
              background-size: 100% 100%;
              margin-top: 2px;
              margin-left: 5px;
              cursor: pointer;
            }
            .coupon-explain-icon-white {
              width: 10px;
              height: 10px;
              background: url("../images/coupon-explain-icon-white.png")
                no-repeat;
              background-size: 100% 100%;
              margin-top: 2px;
              margin-left: 5px;
              cursor: pointer;
            }
          }
        }
      }
      .bg-time-small {
        background: url("../images/coupon-time-small-pc.png") no-repeat;
        background-size: 100% 100%;
      }
      .bg-use-small {
        background: url("../images/coupon-use-small-pc.png") no-repeat;
        background-size: 100% 100%;
      }
      .bg-lose-small {
        background: url("../images/coupon-lose-small-pc.png") no-repeat;
        background-size: 100% 100%;
      }
      .bg-active-small {
        background: url("../images/coupon-bg-small-pc.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
