.allinfoItem {
  display: flex;
  background: #fff;
  padding: 20px 20px;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  .leftImg {
    width: 198px;
    height: 148px;
    border-radius: 4px;
    margin-right: 20px;
  }

  .coverImg {
    height: 100%;
    max-width: 198px;
    border-radius: 4px;
    flex-shrink: 0;
  }

  .rightContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 0px;
  }

  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #333333;
    // margin-bottom: 8px;
  }

  .publishTime {
    color: #9fa1a4;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .times {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .details {
    width: 100%;
    height: 48px;
    color: #9fa1a4;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    white-space: normal;
    line-height: 24px;
    overflow: hidden;
  }

  .ant-tabs .ant-tabs-tab {
    padding: 16px 0;
  }
}
