.orderDetailOutwrap {
  min-height: 100vh;
  background: #f6f7f9;
  .orderDetail-wrap {
    width: 1200px;
    margin: 0 auto;
  }
  .info-origin-level {
    width: 1200px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    border: 1px solid #ff7d00;
    background: #ff7d0014;
    color: #ff7d00;
    font-size: 14px;
    cursor: pointer;
  }
  .info-add-level {
    width: 1200px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    border: 1px solid #fe4e47;
    background: #fe4e4714;
    color: #fe4e47;
    font-size: 14px;
    cursor: pointer;
  }
  .status-box {
    display: flex;
    padding-top: 40px;
    height: 120px;
    width: 358px;
    // background-color: red;
    margin: 0 auto;
  }
  .status-icon {
    width: 80px;
    height: 80px;
  }
  .status-0 {
    background: url(../imgs/awaitPay.png) no-repeat;
    background-size: 100% 100%;
  }
  .status-1 {
    background: url(../imgs/afterSale.png) no-repeat;
    background-size: 100% 100%;
  }
  .status-2 {
    background: url(../imgs/close.png) no-repeat;
    background-size: 100% 100%;
  }
  .status-3 {
    background: url(../imgs/group.png) no-repeat;
    background-size: 100% 100%;
  }
  .status-title {
    color: #323949;
    font-weight: 600;
    margin-top: 12px;
    margin-left: 20px;
    font-size: 20px;
  }
  .status-subTitle {
    color: #9fa1a4;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 4px;
  }
  .order-info-box {
    width: 1200px;
    background-color: #ffffff;
    border: 1px solid #e5e6eb;
    margin: 0px auto;
  }
  .top-60 {
    height: 60px;
    width: 10px;
  }
  .top-40 {
    height: 40px;
    width: 10px;
  }
  .order-info-title {
    height: 57px;
    line-height: 57px;
    padding-left: 20px;
    font-size: 18px;
    border-bottom: 1px solid #e5e6eb;
  }
  .order-info-content {
    padding: 28px 20px;
  }
  .order-info-top {
    display: flex;
    justify-content: space-between;
  }
  .order-info-time {
    font-size: 14px;
    color: #333333;
    line-height: 24px;
  }
  .order-info-time-val {
    color: #9fa1a4;
    font-size: 14px;
    margin-left: 8px;
  }
  .order-info-num-box {
    display: flex;
  }
  .order-info-num {
    font-size: 14px;
    color: #333333;
    line-height: 24px;
  }
  .order-info-num-val {
    font-size: 14px;
    color: #9fa1a4;
    margin-left: 12px;
  }
  .copy-btn {
    width: 60px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 18px;
    background: #f2f3f5;
    color: #4b538a;
    cursor: pointer;
    margin-left: 12px;
  }
  .order-info-table {
    margin-top: 22px;
  }
  .order-info-header {
    height: 44px;
    background: #f5f5f5;
    line-height: 44px;
    text-align: center;
  }
  .order-info-item {
    border: 1px solid #f2f3f5;
  }
  .order-info-border-right {
    border-right: 1px solid #f2f3f5;
  }
  .order-info-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .order-info-commodity {
    display: flex;
  }
  .order-info-img {
    width: 128px;
    height: 72px;
    margin-top: 12px;
    margin-left: 12px;
    margin-bottom: 12px;
  }
  .order-commodity-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
  }
  .commodity-name {
    color: #333333;
    font-size: 14px;
    max-width: 280px;
  }
  .commodity-skuName {
    font-size: 12px;
    color: #9fa1a4;
    line-height: 12px;
    margin-top: 4px;
  }
  .gray {
    color: #9fa1a4;
  }
  .order-info-bottom {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-bottom: 20px;
    // width: 258px;
  }
  .order-info-bottom-box {
    display: flex;
    justify-content: space-between;
    width: 258px;
    margin-bottom: 8px;
  }
  .red-text {
    color: #fe4e47;
  }
  .order-right-box {
    display: flex;
    justify-content: flex-end;
  }
  .bottom-tip {
    margin-top: 20px;
    color: #9fa1a4;
  }
  .bottom-btn {
    width: 150px;
    height: 50px;
    border-radius: 4px;
    background: #fe4e47;
    line-height: 50px;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
      background-color: #fe716b;
    }
  }
}
