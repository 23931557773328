 .page-info-box {
    width: 1200px;
    margin: 0 auto;
    overflow-y: auto;
    border-radius: 6px;
    background: #fff;
    padding: 40px 60px;
    line-height: 34px;
  }
  .page-info-title {
    color: #333228;
    font-family: "PingFang SC";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .page-iframe-box {
    min-height: 600px;
    margin: 0 auto;
    overflow-y: auto;
    background: #fff;
  }


  .xy-modal {
    width: 680px;
    height: 680px;
    text-align: center;
    .modal-title {
      width: 100%;
      height: 57px;
      line-height: 57px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      border-bottom: 1px solid #e5e6eb;
    }
    .ant-modal-content {
      padding: 0;
    }
    .video-pdf-box {
      height: 500px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .modal-footer {
      display: flex;
      justify-content: center;
      padding-bottom: 36px;
      .sure-button {
        width: 380px;
        height: 52px;
        line-height: 52px;
        color: #ffffff;
        background: #e51600;
        text-align: center;
        border-radius: 36px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  
  }