.video-left-box {
  position: relative;
  height: calc(100vh - 68px);
  display: flex;
  justify-content: flex-end;
  .video-left-menu {
    height: 100%;
    width: 68px;
    background: #292929;
    .directory-box {
      width: 100%;
      height: 96px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: transparent;
      font-size: 14px;
      color: #9fa1a4;
      margin-top: 20px;
      .iconfont {
        font-size: 24px;
        width: 24px;
        height: 24px;
        margin-bottom: 10px;
        margin-right: 10px;
      }
      .catalog {
        margin-right: 10px;
      }
    }
    .directory-box-active {
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../images/directory-box-active.png");
      // background: #1C1C1C;
      color: #ffffff;
      .iconfont {
        color: #e51600;
      }
    }
    .handout-box {
      color: #333333;
      background: #d6d6d6;
      height: 96px;
      width: 52px;
      margin: 80px auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      justify-content: center;
      font-size: 14px;
      cursor: pointer;
      .iconfont {
        font-size: 24px;
        width: 24px;
        height: 24px;
        margin-bottom: 10px;
      }
    }
  }
  .video-left-change {
    position: absolute;
    top: 50%;
    margin-top: -60px;
    width: 20px;
    height: 80px;
    left: 0;
    border-radius: 10px 0px 0px 10px;
    margin-left: -20px;
    color: #ffffff;
    background: #292929;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .video-tree {
    background: #1c1c1c;
    width: 650px;
    height: 100%;
    overflow-y: auto;
    padding: 28px;
  }
}
