.order-limit-box {
  width: 1200px;
  height: 350px;
  background-color: #ffffff;
  border: 1px solid #e5e6eb;
  margin: 0px auto;
  .order-header {
    padding: 16px 20px;
  }
  .order-title {
    color: #333333;
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 8px;
  }
  .goods-name {
    color: #333333;
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .limit-box {
    border-top: 1px solid #e5e6eb;
    padding-top: 24px;
    padding-left: 20px;
    .limit-title {
      color: #4e5969;
      font-family: "PingFang SC";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 24px;
    }
    .limit-content {
      display: flex;
      .limit-item {
        width: 240px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 2px solid #f2f3f5;
        margin-right: 16px;
        cursor: pointer;
        .limit-date {
          color: #9fa1a4;
          font-family: "PingFang SC";
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
        }
        .limit-discount {
          color: #ff7d00;
          font-family: "PingFang SC";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
      }
      .limit-checked {
        width: 240px;
        height: 80px;
        border-radius: 8px;
        bottom: 0;
        background-color: rgba(229, 22, 0, 0.05);
        border: 2px solid #e51600;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        .limit-date {
          color: #e51600;
          font-family: "PingFang SC";
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
        }
        .limit-discount {
          color: #e51600;
          font-family: "PingFang SC";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
        .limit-icon {
          border: 20px solid #e51600;
          border-left: 20px solid transparent;
          border-top: 20px solid transparent;
          border-bottom-right-radius: 6px;
          width: 0;
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon-correct {
            color: #ffffff;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}
